
import { Pagination, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import ExportExcel from '../components/Excel/exportXLSX'
import { useCookies } from 'react-cookie'

function StudentList({ downloadLink, students, setPagination }) {
    const perms = JSON.parse(localStorage.getItem("_perms"))

    return (
        <div className='mt-3 students section'>
            <div className="d-flex border-bottom pb-2 mb-3 justify-content-between">
                <h2 className='m-0'>Talabalar ro'yxati</h2>
                <div>
                    <a ref={downloadLink} className='btn btn-secondary text-white' download ><i className="bi me-2 bi-file-earmark-spreadsheet"></i>Download Excell</a>
                    <p className='mb-3'>Jami natijalar: {students.total}</p>
                </div>
            </div>
            <Table striped bordered hover className='text-center'>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Ism Familyasi</th>
                        <th>Fakulteti</th>
                        <th >Yo'nalishi</th>
                        <th>Guruhi</th>
                        <th>To'lov shakli</th>
                        <th>Ta'lim shakli</th>
                        <th>Harakatlar</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        students?.data?.length === 0 ? (
                            <tr>
                                <td colSpan={9}>Talaba topilmadi</td>
                            </tr>
                        ) :
                            students?.data?.map((student, index) => (
                                <tr key={index}>

                                    <td>{index + 1}</td>
                                    <td className={!student.image ? "text-danger" : ""}>{student.full_name}</td>
                                    <td >{student.educational_information.department?.name}</td>
                                    <td>{student.educational_information.group.specialty.specialty}</td>
                                    <td className='text-break'>{student.educational_information.group.group}</td>
                                    <td>{student.educational_information.paymentForm}</td>
                                    <td>{student.educational_information.educationForm}</td>
                                    <td>
                                        <Link className='btn btn-primary text-white me-2' to={student.student_id_number.toString()}><i className="bi bi-eye-fill"></i></Link>
                                        {perms.includes("update_student") && <Link className='btn btn-primary text-white me-2' to={`/add/${student.student_id_number.toString()}`}><i className="bi bi-pen"></i></Link>}
                                    </td>
                                </tr>
                            ))
                    }
                </tbody>
            </Table>
            <Pagination className='justify-content-center'>
                {
                    students?.links?.length >= 4 &&
                    students?.links?.map((link, index) => (
                        <Pagination.Item disabled={link.url === null} onClick={() => setPagination(link.url)} key={index} active={link.active}>
                            {link.label.replace('&laquo; Previous', 'Oldingi').replace('Next &raquo;', 'Keyingi')}
                        </Pagination.Item>
                    ))
                }</Pagination>
        </div>
    )
}

export default StudentList
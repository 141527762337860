export const enter_comments = [
    {
        name: "O'qishini ko'chirgan",
        value: "10",
    },
    {
        name: "Tiklagan",
        value: "11",
    },
    {
        name: "Akademik ta'tilgan qaytgan",
        value: "12",
    },
    {
        name: "Boshqa ooliygohdan ko'chirgan",
        value: "13",
    },
    {
        name: "Xorijdan ko'chirgan",
        value: "14",
    },
]
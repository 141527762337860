import { Link, Navigate } from 'react-router-dom';
import { useAuth } from '../routes/Auth';

function NotMatch() {

    const auth = useAuth()
    const isAuthorized = auth.user;

    if (!isAuthorized) {
        return <Navigate to="/login" />;
    }

    return (
        <div className="mainbox">
            <div className="err">404</div>
            <p>Opps sizda bunday sahifa topilmadi <br />
                <span>Siz bosh sahifaga qaytishingiz mumkin!!!</span> </p>
            <Link to="/" className='home-btn text-primary fs-1 fw-bold' >Bosh sahifa</Link>
        </div>
    )
}

export default NotMatch


import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Accordion, Button, Col, Form, Row } from 'react-bootstrap';
import { clearFilter, findChange } from '../store/Slices/findSlice/FindSlice';
import { useDispatch, useSelector } from 'react-redux';
import serializeFormQuery from './serializeFormQuery';
import { useCookies } from 'react-cookie';

function Filter({ searchParams, setSearchParams }) {
    const dispatch = useDispatch();
    const [cookie] = useCookies();

    const findStore = useSelector(state => state.find)
    const [groupList, setGroupList] = useState([]);
    const [curriculumList, setCurriculumList] = useState([]);
    const [departments, setDepartments] = useState();
    const [countries, setCountries] = useState([]);
    const [provinces, setProvinces] = useState([]);
    const [disctricts, setDisctricts] = useState([]);

    useEffect(() => {
        // Agar faktet id mavjud bo'lsa userga department ko'rsatilmaydi.
        if (!cookie.department_id) {
            axios
                .get(`departments-list`)
                .then((res) => {
                    setDepartments(res.data.data.departments);
                    // setDepartments(res.data.data.departments.map(item => ({ label: item.name, value: item.id })));
                })
        }

        axios.get("countries").then(res => setCountries(res.data))


        searchParams.get(["educational_information[department_id]"]) &&
            dispatch(findChange({
                name: "department_id",
                type: "educational_information",
                value: searchParams.get(["educational_information[department_id]"])
            }))

        return function () {
            dispatch(clearFilter())
        }
    }, [cookie.department_id, dispatch])

    useEffect(() => {
        if (cookie["_role"] === "dean")
            axios
                .get(`groups-list`)
                .then((res) => {
                    setCurriculumList(res.data.data.specialities);
                    // setCurriculumList(res.data.data.specialities.map(item => ({ label: item.specialty, value: item.specialty_code })));
                })
    }, [])

    useEffect(() => {
        if (findStore.educational_information.department_id)
            axios
                .get(`departments-list/${findStore.educational_information.department_id}`)
                .then((res) => {
                    setCurriculumList(res.data.data.specialities);
                    // setCurriculumList(res.data.data.specialities.map(item => ({ label: item.specialty, value: item.specialty_code })));
                })

        // (searchParams.get(["educational_information[specality]"]) !== "null" || searchParams.get(["educational_information[specality]"])) &&
        // dispatch(findChange({
        //     name: "specality",
        //     type: "educational_information",
        //     value: searchParams.get(["educational_information[specality]"])
        // }))
    }, [dispatch, findStore.educational_information.department_id])


    useEffect(() => {
        if (findStore.educational_information.specality?.length !== 0 && findStore.educational_information.specality !== "null") {
            if (cookie["_role"] === "dean")
                axios
                    .get(`group/${findStore.educational_information.specality}`)
                    .then((res) => {
                        setGroupList(res.data.data.groups)
                        // setGroupList(res.data.data.groups.map(item => ({ label: item.group, value: item.id })))
                    })
            if (cookie["_role"] === "super_admin" || cookie["_role"] === "student_staff")
                axios
                    .get(`specialties-list/${findStore.educational_information.specality}`)
                    .then((res) => {
                        setGroupList(res.data.data.groups)
                        // setGroupList(res.data.data.groups.map(item => ({ label: item.group, value: item.id })))
                    })
        }

        if (cookie["_role"] === "tutor") {
            axios
                .get(`own-groups-list`)
                .then((res) => {
                    setGroupList(res.data.data.groups)
                })
        }

        searchParams.get(["educational_information[group_id]"]) &&
            dispatch(findChange({
                name: "group_id",
                type: "educational_information",
                value: searchParams.get(["educational_information[group_id]"])
            }))
    }, [dispatch, findStore.educational_information.specality])


    useEffect(() => {
        axios
            .get(`provinces?country=${findStore.accommodations.country}`)
            .then((res) => {
                setProvinces(res.data.data.provinces);
                // setCurriculumList(res.data.data.specialities.map(item => ({ label: item.specialty, value: item.specialty_code })));
            })

    }, [dispatch, findStore.accommodations.country])

    useEffect(() => {
        axios
            .get(`districts?province=${findStore.accommodations.province}`)
            .then((res) => {
                setDisctricts(res.data.data.districts);
            })

    }, [dispatch, findStore.accommodations.province])



    function handleSend(e) {
        e.preventDefault()
        let params = serializeFormQuery(findStore);
        setSearchParams(params)
    }

    return (
        <div className='section'>
            <h2>Qidirish paneli</h2>
            <form onSubmit={handleSend}>
                <Row>
                    {
                        !cookie.department_id && (
                            <Col xs={4}>
                                <Form.Label>Fakultetni tanlang</Form.Label>
                                {/* <Select
                                    isClearable
                                    defaultValue={
                                        departments?.find(item => item.value == findStore["educational_information"]['department_id'])
                                    }
                                    onChange={e => dispatch(findChange({ name: "department_id", type: "educational_information", value: e.value }))}
                                    options={departments}
                                /> */}
                                <Form.Select
                                    value={findStore["educational_information"]['department_id']}
                                    onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                    data-type="educational_information"
                                    name="department_id">
                                    <option value="">Tanlang</option>
                                    {
                                        departments?.map(department => (
                                            <option key={department.id} value={department.id}>{department.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                        )
                    }
                    {
                        cookie["_role"] !== "tutor" &&
                        <Col xs={4}>
                            <Form.Label>Yo'nalishni tanlang</Form.Label>
                            {/* <Select
                                // defaultValue={
                                //     departments?.find(item => item.value == findStore["educational_information"]['department_id'])
                                // }
                                onChange={e => dispatch(findChange({ name: "specality", type: "educational_information", value: e.value }))}
                                options={curriculumList}
                            /> */}
                            <Form.Select onChange={e =>
                                dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                data-type="educational_information"
                                value={findStore["educational_information"]['specality']}
                                name="specality">
                                <option value="">Tanlang</option>
                                {
                                    curriculumList?.map(curriculum => (
                                        <option key={curriculum.specialty_code} value={curriculum.specialty_code}>{curriculum.specialty}</option>
                                    ))
                                }
                            </Form.Select>
                        </Col>
                    }

                    <Col xs={4}>
                        <Form.Label>Guruhni tanlang</Form.Label>
                        {/* <Select
                            onChange={e => dispatch(findChange({ name: "group_id", type: "educational_information", value: e.value }))}
                            options={groupList}
                        /> */}
                        <Form.Select onChange={e =>
                            dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                            data-type="educational_information"
                            value={findStore["educational_information"]['group_id']}
                            name="group_id">
                            <option value="">Tanlang</option>
                            {
                                groupList?.map(group => (
                                    <option key={group.id} value={group.id}>{group.group}</option>
                                ))
                            }
                        </Form.Select>
                    </Col>
                    <Col className='mt-3' xs={4}>
                        <Form.Label>Ta'lim turi</Form.Label>
                        <Form.Select
                            onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                            data-type="educational_information"
                            value={findStore["educational_information"]['paymentForm']}
                            name="paymentForm">
                            <option value="">Tanlang</option>
                            <option value="Davlat granti">Davlat granti</option>
                            <option value="To‘lov-shartnoma">To‘lov-shartnoma</option>
                        </Form.Select>
                    </Col>
                    <Col className='mt-3' xs={4}>
                        <Form.Label>Jinsi</Form.Label>
                        <Form.Select
                            onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                            data-type="students"
                            value={findStore["students"]['gender']}
                            name="gender">
                            <option value="">Tanlang</option>
                            <option value="1">Erkak</option>
                            <option value="0">Ayol</option>
                        </Form.Select>
                    </Col>
                    <Col className='mt-3' xs={4}>
                        <Form.Label>Kursi</Form.Label>
                        <Form.Select
                            onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                            data-type="educational_information"
                            value={findStore["educational_information"]['level']}
                            name="level">
                            <option value="">Tanlang</option>
                            <option value="1">1-kurs</option>
                            <option value="2">2-kurs</option>
                            <option value="3">3-kurs</option>
                            <option value="4">4-kurs</option>
                            <option value="5">5-kurs</option>
                        </Form.Select>
                    </Col>
                    <Col xs={12}>
                        <p className='mt-3 fw-bold'>Qo'shimcha filtrlar</p>
                        <Accordion className='mt-2' alwaysOpen defaultActiveKey="null">
                            {/* Ism va id */}
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Shaxsiy ma’lumotlari bo'yicha filtrlar</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col xs={4}>
                                            <Form.Label>Student id raqami</Form.Label>
                                            <Form.Control
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value.toUpperCase() }))}
                                                data-type="students"
                                                value={findStore["students"]["student_id_number"]}
                                                name="student_id_number">
                                            </Form.Control>
                                        </Col>
                                        <Col xs={4}>
                                            <Form.Label>Passport raqami</Form.Label>
                                            <Form.Control
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value.toUpperCase() }))}
                                                data-type="students"
                                                value={findStore["students"]["passport"]}
                                                name="passport">
                                            </Form.Control>
                                        </Col>
                                        <Col xs={4}>
                                            <Form.Label>JSHSHIR</Form.Label>
                                            <Form.Control
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value.toUpperCase() }))}
                                                data-type="students"
                                                value={findStore["students"]["JSHSHIR"]}
                                                name="JSHSHIR">
                                            </Form.Control>
                                        </Col>
                                        <Col xs={4}>
                                            <Form.Label>To'liq ismi</Form.Label>
                                            <Form.Control
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value.toUpperCase() }))}
                                                data-type="students"
                                                value={findStore["students"]["full_name"]}
                                                name="full_name">
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* maxsus imkoniyatlar */}
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Maxsus inkoniyati bo'yicha filtrlar</Accordion.Header>
                                <Accordion.Body>
                                    <Form.Check
                                        onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.checked }))}
                                        data-type="additional_information"
                                        value={findStore["additional_information"]["iron_notebook"]}
                                        name="iron_notebook"
                                        label="Temir daftar"
                                    />
                                    <Form.Check
                                        onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.checked }))}
                                        data-type="additional_information"
                                        value={findStore["additional_information"]["youth_notebook"]}
                                        name="youth_notebook"
                                        label="Yoshlar daftaftari"
                                    />
                                    <Form.Check
                                        onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.checked }))}
                                        data-type="additional_information"
                                        value={findStore["additional_information"]["orphan"]}
                                        name="orphan"
                                        label="Davlat qaramog'ida (chin yetim)"
                                    />
                                    <Form.Check
                                        onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.checked }))}
                                        data-type="additional_information"
                                        value={findStore["additional_information"]["amputatuion"]}
                                        name="amputatuion"
                                        label="Nogironligi mavjud bo‘lgan shaxslar uchun ajratilgan qo‘shimcha kvota"
                                    />
                                    <Form.Check
                                        onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.checked }))}
                                        data-type="additional_information"
                                        value={findStore["additional_information"]["ligota"]}
                                        name="ligota"
                                        label="Muddatli harbiy xizmatni o‘tab harbiy qism qo‘mondonligi tavsiyanomasiga ega"
                                    />
                                    <Form.Check
                                        onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.checked }))}
                                        data-type="additional_information"
                                        value={findStore["additional_information"]["iab_child"]}
                                        name="iab_child"
                                        label="Ichki ishlar organlari xodimlari farzandlari uchun ajratilgan qo‘shimcha kvota"
                                    />
                                    <Form.Check
                                        onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.checked }))}
                                        data-type="additional_information"
                                        value={findStore["additional_information"]["military_child"]}
                                        name="military_child"
                                        label="Qurolli Kuchlari xodimlarining farzandlari uchun ajratilgan qo‘shimcha kvota"
                                    />
                                    <Form.Check
                                        onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.checked }))}
                                        data-type="additional_information"
                                        value={findStore["additional_information"]["desert"]}
                                        name="desert"
                                        label="Olis cho‘l tumanlari uchun ajratilgan qo'shimcha kvota"
                                    />
                                    <Form.Check
                                        onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.checked }))}
                                        data-type="additional_information"
                                        value={findStore["additional_information"]["woman"]}
                                        name="woman"
                                        label="Xotin-qizlarni qo‘llab-quvvatlash maqsadida berilgan tavsiyanoma asosida qabul"
                                    />
                                    <Form.Check
                                        onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.checked }))}
                                        data-type="additional_information"
                                        value={findStore["additional_information"]["purpose"]}
                                        name="purpose"
                                        label="Maqsadli qabul"
                                    />


                                </Accordion.Body>
                            </Accordion.Item>
                            {/* Yashash joyi */}
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Yashash joyi ma'lumotlari bo'yicha filtrlar</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col sm={4}>
                                            <Form.Label>Davlat</Form.Label>
                                            <Form.Select
                                                value={findStore["accommodations"]['country']}
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="accommodations"
                                                name="country">
                                                <option value="">Tanlang</option>
                                                {
                                                    countries?.map(country => (
                                                        <option key={country.country} value={country.country}>{country.country}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Viloyati</Form.Label>
                                            <Form.Select
                                                value={findStore["accommodations"]['province']}
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="accommodations"
                                                name="province">
                                                <option value="">Tanlang</option>
                                                {
                                                    provinces?.map(province => (
                                                        <option key={province.province} value={province.province}>{province.province}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Tumani</Form.Label>
                                            <Form.Select
                                                value={findStore["accommodations"]['disctrict']}
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="accommodations"
                                                name="disctrict">
                                                <option value="">Tanlang</option>
                                                {
                                                    disctricts?.map(disctrict => (
                                                        <option key={disctrict.disctrict} value={disctrict.disctrict}>{disctrict.disctrict}</option>
                                                    ))
                                                }
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/* Buyruqlari */}
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Buyruqlari bo'yicha filtrlar</Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col sm={4}>
                                            <Form.Label>Kirish buyrugi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['enter_order']}
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                name="enter_order">
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili boshlanishi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['enter_date']['begin']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="begin"
                                                name="enter_date"
                                                type='date'>
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili tugashi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['enter_date']['end']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="end"
                                                type='date'
                                                name="enter_date">
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col sm={4}>
                                            <Form.Label>Talabalar safidan chiqarilgan buyrug'i</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['leave_order']}
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                name="leave_order">
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili boshlanishi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['leave_date']['begin']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="begin"
                                                name="leave_date"
                                                type='date'>
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili tugashi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['leave_date']['end']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="end"
                                                type='date'
                                                name="leave_date">
                                            </Form.Control>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col sm={4}>
                                            <Form.Label>Talabaga akademik ta'til berilgan buyrug'i</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['academic_holiday']}
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                name="academic_holiday">
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili boshlanishi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['academic_holiday_date']['begin']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="begin"
                                                type='date'
                                                name="academic_holiday_date">
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili tugashi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['academic_holiday_date']['end']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="end"
                                                type='date'
                                                name="academic_holiday_date">
                                            </Form.Control>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col sm={4}>
                                            <Form.Label>Talabaga hayfsan berilgan buyrug'i</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['sorry_command']}
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                name="sorry_command">
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili boshlanishi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['sorry_command_date']['begin']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="begin"
                                                type='date'
                                                name="sorry_command_date">
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili tugashi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['sorry_command_date']['end']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="end"
                                                type='date'
                                                name="sorry_command_date">
                                            </Form.Control>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col sm={4}>
                                            <Form.Label>Talaba 1-kursdan 2-kursga o'tish buyrug'i</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['first_to_second']}
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                name="first_to_second">
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili boshlanishi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['first_to_second_date']['begin']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="begin"
                                                type='date'
                                                name="first_to_second_date">
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili tugashi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['first_to_second_date']['end']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="end"
                                                type='date'
                                                name="first_to_second_date">
                                            </Form.Control>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col sm={4}>
                                            <Form.Label>Talaba 2-kursdan 3-kursga o'tish buyrug'i</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['second_to_third']}
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="second_to_third"
                                                name="first_to_second">
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili boshlanishi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['second_to_third_date']['begin']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="begin"
                                                type='date'
                                                name="second_to_third_date">
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili tugashi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['second_to_third_date']['end']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="end"
                                                type='date'
                                                name="second_to_third_date">
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col sm={4}>
                                            <Form.Label>Universitetni tamomlash haqida buyrug'i</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['finish_command']}
                                                onChange={e => dispatch(findChange({ name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                name="finish_command">
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili boshlanishi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['finish_command_date']['begin']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="begin"
                                                type='date'
                                                name="finish_command_date">
                                            </Form.Control>
                                        </Col>
                                        <Col sm={4}>
                                            <Form.Label>Buyruq yili tugashi</Form.Label>
                                            <Form.Control
                                                value={findStore["study_informations"]['finish_command_date']['end']}
                                                onChange={e => dispatch(findChange({ date: e.target.dataset.date, name: e.target.name, type: e.target.dataset.type, value: e.target.value }))}
                                                data-type="study_informations"
                                                data-date="end"
                                                type='date'
                                                name="finish_command_date">
                                            </Form.Control>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>


                    <Col xs={12}>
                        <Button onClick={() => dispatch(clearFilter())} type='button' variant='info' className="w-25 text-white my-3">Filtrni tozalash</Button>
                    </Col>

                    <Col xs={12} className="text-center" >
                        <Button type='submit' className="w-50 my-3">Izlash</Button>
                    </Col>
                </Row>
            </form>
        </div>
    )
}

export default Filter
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../routes/Auth';
import { Form, Button, Spinner } from 'react-bootstrap'

function Login() {
    const [user, setUser] = useState({
        name: "",
        password: "",
    });
    const [errorState, setErrorState] = useState(null);
    const [validated, setValidated] = useState(false);
    const auth = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const loading = auth.loading;

    const redirectLocation = location.state?.path || "/";
    useEffect(() => {
        if (auth.user) {
            navigate(redirectLocation)
        }
    }, [])
    function handleSubmit(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            setErrorState(null)
            auth.login(user, redirectLocation, setErrorState);
        }
        setValidated(true);
    }
    return (
        <div className='login'>
            <Form noValidate validated={validated} onSubmit={handleSubmit} className='login-form'>
                <h2 className='text-center'>Tizimga kirish</h2>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Login</Form.Label>
                    <Form.Control required name='name' value={user.name} onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })} type="text" placeholder="Loginni kiritng" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Parol</Form.Label>
                    <Form.Control required name='password' value={user.password} onChange={(e) => setUser({ ...user, [e.target.name]: e.target.value })} type="password" placeholder="Parolni kirting" />
                    <Form.Text className='text-danger' type="invalid">{errorState === 403 && "Login yoki parol xato"}</Form.Text>
                </Form.Group>
                <div className='text-center'>
                    <Button className='w-100' variant="primary" type="submit">
                        {
                            loading ?
                                <Spinner size="sm" animation="border" variant="light" /> : <span>Kirish</span>
                        }
                    </Button>
                </div>
            </Form>
        </div>
    )
}

export default Login
import axios from 'axios';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function DeleteConform({ show, setShow }) {
    // get student id for delete user
    const { id } = useParams();

    const handleClose = () => setShow(false);
    const navigate = useNavigate();

    // Request for delete student
    const handleDelete = () => {
        axios.delete(`/students/${id}`).then(res => {
            toast.success("Mufaqqiyatli o'chirildi.");
            handleClose()
            navigate("/students")
        }).catch(err => {
            toast.error("Xatolik yuz berdi.");
            handleClose()
        })
    }
    return (
        <>
            <Modal show={show} centered onHide={handleClose}>
                <Modal.Header className='bg-danger text-white' closeButton>
                    <Modal.Title className='fs-6'>Talabani o'chirishni tasdiqlang</Modal.Title>
                </Modal.Header>
                <Modal.Body>Siz haqiqatdan ham talabani bazadan o'chirmoqchimisiz. <br /> <b>Bu bilan talaba ma'lumotlari bazada umuman yo'q bo'lib ketadi</b></Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Yopish
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        O'chirish
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DeleteConform;
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Filter from '../components/Filter';
import Loader from '../components/Loader';
import StudentList from './StudentList';
import { useRef } from 'react';

function Show() {
    let [searchParams, setSearchParams] = useSearchParams();
    const [students, setStudents] = useState({})
    const [loader, setLoader] = useState(false);
    const [param, setParam] = useState('');
    const downloadLink = useRef()


    useEffect(() => {
        setLoader(true)
        // vaqatinchalik specality bo'yicha izlash qo'yilsa o'chiriladi.
        let specality = searchParams.get("educational_information[specality]")

        searchParams.delete("educational_information[specality]");

        const param = searchParams.toString()
        // setParam(searchParams.toString())
        searchParams.set("educational_information[specality]", specality)

        if (!!param) {
            downloadLink.current.href = "https://info.samdu.uz/api/download?" + param;
            downloadLink.current.classList.add("btn-success")
        } else {
            downloadLink.current.removeAttribute("href");
            downloadLink.current.classList.remove("btn-success")
            downloadLink.current.classList.add("btn-secondary")
        }

        axios.get("students?" + param).then(res => {
            setStudents(res?.data.data.students);
            setLoader(false)
        });

    }, [searchParams])


    function setPagination(data) {
        let page = data.slice(data.search("page=") + 5);
        searchParams.set("page", page)
        setSearchParams(searchParams)
    }


    return (
        <div>
            <Loader loading={loader} />
            <Filter searchParams={searchParams} setSearchParams={setSearchParams} />
            <StudentList downloadLink={downloadLink} students={students} setPagination={setPagination} />
        </div>
    )
}

export default Show
import axios from 'axios';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap'
import { useCookies } from 'react-cookie';
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select';

function NewUser() {
    const [validated, setValidated] = useState(false);
    const [roles, setRoles] = useState([])
    const [cookie] = useCookies()
    const [departments, setDepartments] = useState([])
    const [groups, setGroups] = useState([])
    const initialUser = {
        name: "",
        full_name: "",
        password: "",
        email: "",
        role: "",
        groups: [],
        department: ""
    }

    const [user, setUser] = useState(initialUser);

    useEffect(() => {
        if (cookie["_role"] !== "dean") {
            axios.get('spadmin/roles').then(res => setRoles(res.data.data.roles)).catch(err => console.log(err))
            axios.get('departments-list').then(res => setDepartments(res.data.data.departments)).catch(err => console.log(err))
        }
        else
            axios.get('groups').then(res => {
                setGroups(res.data.data.groups.map(item => ({ label: item.group, value: item.id })))
            })
    }, [cookie])

    function handleChange(e) {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            if (cookie["_role"] === "dean") {
                const newData =
                {
                    ...user,
                    groups: user.groups.map(item => item.value)
                }
                axios.post('tyutors', newData).then(res => {
                    setUser(initialUser)
                    toast.success("Foydalanuvchi yaratildi !!!")
                    setValidated(false);
                }).catch(err => {
                    toast.error(err.response.data.message === "The name has already been taken." ? "Bunday loginga ega foydalanuvchi allaqachon mavjud" : err.response.data.message)
                })
            }
            else
                axios.post('spadmin/register', user).then(res => {
                    setUser(initialUser)
                    toast.success("Foydalanuvchi yaratildi !!!")
                    setValidated(false);
                }).catch(err => toast.error(err.message))
        }

        setValidated(true);
    };

    return (
        <div className='section'>
            <h2>Yangi foydalanuvchi qo'shish</h2>

            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Foydalanuvchi loginini kiriting</Form.Label>
                    <Form.Control onChange={handleChange} value={user.name} required name='name' type="text" placeholder="Username" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="fulname">
                    <Form.Label>Foydalanuvchi ismini kiriting</Form.Label>
                    <Form.Control onChange={handleChange} value={user.full_name} required name='full_name' type="text" placeholder="F.I.Sh" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Parolini kiriting</Form.Label>
                    <Form.Control minLength={6} onChange={handleChange} value={user.password} required name='password' type="password" placeholder="Parol" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Emailni kiriting</Form.Label>
                    <Form.Control onChange={handleChange} value={user.email} required name='email' type="email" placeholder="Email" />
                </Form.Group>
                {
                    cookie["_role"] !== "dean" ? (
                        <>
                            <Form.Group className="mb-3" controlId="formBasicRole">
                                <Form.Label>Foydalanuvchi rolini kiriting</Form.Label>
                                <Form.Select onChange={handleChange} name="role" value={user.role} required>
                                    <option value="">Tanlang</option>
                                    {
                                        roles?.map(role => (
                                            <option key={role.id} value={role.id}>{role.role}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicDepartments">
                                <Form.Label>Foydalanuvchi fakultetini tanlang</Form.Label>
                                <Form.Select disabled={+user.role === 3 || +user.role === 4 ? false : true} onChange={handleChange} name="department" value={user.department} required>
                                    <option value="">Tanlang</option>
                                    {
                                        departments?.map(department => (
                                            <option key={department.id} value={department.id}>{department.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                        </>
                    ) : (
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Gruhlari belgilang</Form.Label>
                            <Select
                                value={user.groups.length === 0 ? "" : user.groups}
                                name='groups'
                                onChange={(newValue) => {
                                    setUser({
                                        ...user,
                                        groups: newValue
                                    });
                                }} placeholder="Guruhlarni tanlang" className='mb-3' isMulti options={groups} />
                        </Form.Group>
                    )
                }

                <Button variant="primary" type="submit">
                    Yuborish
                </Button>
                <ToastContainer position="bottom-right" />
            </Form>
        </div>
    )
}

export default NewUser
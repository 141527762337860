import jsPDF from "jspdf";
import "jspdf-autotable";
import { font, fontBold } from "./font";
import { URL_IMG } from "../../routes/constants";
async function CreatePdf(document, id, title, image) {
  // const data = [...document];

  const callAddFont = function () {
    this.addFileToVFS("times new roman bold-normal.ttf", fontBold);
    this.addFont("times new roman bold-normal.ttf", "times new roman", "bold");
    this.addFileToVFS("times new roman-normal.ttf", font);
    this.addFont("times new roman-normal.ttf", "times new roman", "normal");
  };

  // const makeImage = await axios.get(`images/${image}`);

  jsPDF.API.events.push(["addFonts", callAddFont]);

  const doc = new jsPDF("p", "pt", "a4", true).setProperties({
    title: title,
  });

  let pageHeight = doc.internal.pageSize.height,
    pageWidth = doc.internal.pageSize.getWidth(),
    lineHeight = 1.5,
    margin = 50,
    maxLineWidth = pageWidth - margin * 2,
    fontSize = 12,
    oneLineHeight = fontSize * lineHeight;
  doc.setFont("times new roman");
  let verticalOffset = 0;
  verticalOffset += oneLineHeight * 3;

  doc.addImage(
    // makeImage.data.data.image,
    URL_IMG + image,
    "JPEG",
    maxLineWidth - 25,
    verticalOffset - 20,
    80,
    106
  );

  let header = doc
    .setFont("times new roman", "bold")
    .setFontSize(16)
    .splitTextToSize("MA'LUMOTNOMA");
  doc.text(header, pageWidth / 2, verticalOffset, {
    maxWidth: maxLineWidth,
    align: "center",
  });

  verticalOffset += oneLineHeight * 1.5;

  document.forEach((item) => {
    if (verticalOffset >= pageHeight - oneLineHeight * 2 || item.page) {
      doc.addPage();
      verticalOffset = oneLineHeight * 3; // Restart height position
    }

    if (item.name === "text") {
      addText(item);
    }
    if (item.name === "work") {
      console.log(item);
      work(item);
    }

    if (item.name === "table") {
      drawTable(item.body);
    }
    if (item.name === "line") {
      drawLine(item.body);
    }
  });

  function work(text) {
    let textLines1 = doc
      .setFont("times new roman", "normal")
      .setFontSize(text.size ? text.size : fontSize)
      .splitTextToSize(text.text1);
    doc.internal.write(0, "Tw");
    let Xaxis = margin;
    doc.text(textLines1, Xaxis, verticalOffset, {
      maxWidth: maxLineWidth,
      align: "left",
    });
    let textLines2 = doc
      .setFont("times new roman", "normal")
      .setFontSize(text.size ? text.size : fontSize)
      .splitTextToSize(text.text2, maxLineWidth - pageWidth / 6);
    doc.internal.write(0, "Tw");
    Xaxis = pageWidth / 5;
    doc.text(textLines2, Xaxis, verticalOffset, {
      maxWidth: maxLineWidth,
    });

    verticalOffset += textLines2.length * fontSize * lineHeight;
  }

  function addText(text) {
    let textLines = doc
      .setFont("times new roman", text.weight)
      .setFontSize(text.size ? text.size : fontSize)
      .splitTextToSize(
        text.text,
        text.width ? maxLineWidth / text.width : maxLineWidth
      );
    let Xaxis = pageWidth / 2;
    if (text.align === "center") {
      Xaxis = pageWidth / 2;
    } else if (text.align1 === "right") {
      Xaxis = (pageWidth / 3) * 2;
    } else {
      Xaxis = margin;
    }

    doc.internal.write(0, "Tw");

    doc.text(textLines, Xaxis, verticalOffset, {
      maxWidth: maxLineWidth,
      align: text.align ? text.align : "justify",
    });

    verticalOffset += textLines.length * fontSize * lineHeight * text.height;
  }

  function drawLine(body) {
    let verticalOffset1 = verticalOffset;

    body.body.forEach((item) => {
      let Xaxis2 = pageWidth / 2;
      let Xaxis1 = margin;
      // ---------- Birinchi title qismlarni joylashitirib olamiz
      if (item[0].line !== "one") {
        let textLines1 = doc
          .setFont("times new roman", "bold")
          .setFontSize(fontSize)
          .splitTextToSize(item[0].title, maxLineWidth / 2);

        doc.text(textLines1, Xaxis1, verticalOffset1, {
          maxWidth: maxLineWidth / 2,
          align: "left",
        });

        if (item[1]) {
          let textLines2 = doc.splitTextToSize(item[1].title, maxLineWidth / 2);

          doc.text(textLines2, Xaxis2, verticalOffset1, {
            maxWidth: maxLineWidth / 2,
            align: "left",
          });
        }

        verticalOffset1 += textLines1.length * oneLineHeight * 0.8;

        // ------   text qismini joylashtish qismi
        let textLines3 = doc
          .setFont("times new roman", "normal")
          .setFontSize(fontSize)
          .splitTextToSize(item[0].text, maxLineWidth / 2);

        doc.text(textLines3, Xaxis1, verticalOffset1, {
          maxWidth: maxLineWidth / 2,
          align: "left",
        });
        let textLines2;
        if (item[1]) {
          textLines2 = doc.splitTextToSize(item[1].text, maxLineWidth / 2);
          doc.text(textLines2, Xaxis2, verticalOffset1, {
            maxWidth: maxLineWidth / 2,
            align: "left",
          });
        }

        verticalOffset1 += textLines3.length * fontSize * lineHeight * 1.4;
      } else {
        let textLines1 = doc
          .setFont("times new roman", "bold")
          .setFontSize(fontSize)
          .splitTextToSize(item[0].title, maxLineWidth / 2);

        doc.text(textLines1, Xaxis1, verticalOffset1, {
          maxWidth: maxLineWidth / 2,
          align: "left",
        });

        console.log(item[1]);
        let textLines2 = doc.setFont("times new roman", "regular").splitTextToSize(item[1].title, maxLineWidth / 2);
        if (item[1]) {

          doc.text(textLines2, Xaxis2, verticalOffset1, {
            maxWidth: maxLineWidth / 2,
            align: "left",
          });
        }

        verticalOffset1 += textLines1.length * oneLineHeight;
      }
    });

    verticalOffset = verticalOffset1 + oneLineHeight;
  }

  function drawTable(body) {
    const headers = [
      "Qarindoshligi",
      "Familiyasi, ismi, otasining ismi",
      "Tugʼilgan yili va joyi",
      "Ish joyi va lavozimi",
      "Turar joyi",
    ];

    const content = {
      startY: verticalOffset - 10,
      theme: "plain",
      head: [headers],
      body: body,
      font: "times new roman",
      margin: 0,
      rowPageBreak: "avoid",
      bodyStyles: {
        lineColor: [0, 0, 0],
        lineWidth: 0.001,
        font: "times new roman",
        halign: "center",
        fontSize: 12,
      },
      headStyles: {
        halign: "center",
        fontStyle: "bold",
        fontSize: 12,
        lineColor: [0, 0, 0],
        lineWidth: 0.001,
        font: "times new roman",
      },
    };

    doc.autoTable(content);
    verticalOffset = doc.lastAutoTable.finalY + oneLineHeight;
  }

  doc.save(title);
}

export default CreatePdf;

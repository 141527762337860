import React from 'react'
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux'
import { handleBlur, handleChange } from '../../store/Slices/studentSlice/studentSlice';

export default function MySelect({ section, index, options, required }) {
    const dispatch = useDispatch();
    return (
        <div>
            <Form.Select
                data-index={index}
                data-type={section}
                required={required ? true : false}

                onChange={(e) => dispatch(handleChange({ type: e.target.dataset.type, index: e.target.dataset.index, value: e.target.value }))}
                aria-label="Default select">
                <option value="" >Qiymatsiz</option>
                {
                    options?.map(option => (
                        <option key={option.value} value={option.value}>{option.name}</option>
                    ))
                }
            </Form.Select>
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </div>
    )
}

import { enter_comments } from "./enter_commit";


export const defaultData = {
  loading: false,
  body: {
    passport: [
      {
        value: "",
        name: "full_name",
        placeholder: "Falonchayev",
        label: "FISH",
        required: false,
      },
      {
        value: "",
        name: "phone",
        placeholder: "xx-xxx-xxxx",
        // pattern: "[0-9]{2}-[0-9]{3}-[0-9]{4}",
        required: false,
        label: "Telefon raqami",
      },
      {
        value: "",
        name: "image",
        placeholder: "",
        label: "Rasmi",
        type: "file",
        required: false,
      },
    ],
    education: [
      // {
      //   value: "",
      //   name: "department",
      //   placeholder: "Maktabgacha va boshlang'ich ta'lim",
      //   label: "Fakultet",
      //   required: false,
      // },
      // {
      //   value: "",
      //   name: "specialty_code",
      //   placeholder: "5111700",
      //   label: "Yo'nalish raqami",
      //   required: false,
      // },
      // {
      //   value: "",
      //   placeholder: "Boshlang’ich Ta'lim va sport-tarbiyaviy ish",
      //   label: "Yo'nalish nomi",
      //   name: "specialty",
      //   required: false,
      // },
      // {
      //   value: "",
      //   name: "group",
      //   placeholder: "201",
      //   label: "Guruh nomi",
      //   required: false,
      // },
      // {
      //   value: "",
      //   name: "educationForm",
      //   placeholder: "Kunduzgi",
      //   label: "Ta'lim shakli",
      //   required: false,
      // },
      // {
      //   value: "",
      //   name: "educationType",
      //   placeholder: "Bakalavr",
      //   label: "Ta'lim turi",
      //   required: false,
      // },
      // {
      //   value: "",
      //   name: "level",
      //   placeholder: "2-kurs",
      //   label: "Kurs",
      //   required: false,
      // },
      // {
      //   value: "",
      //   name: "paymentForm",
      //   placeholder: "To'lov-shartnoma",
      //   label: "To'lov shakli",
      //   required: false,
      // },
      {
        value: "",
        name: "mark",
        placeholder: "180",
        label: "Kirish bali",
        required: false,
      },
      // {
      //   value: "",
      //   name: "department_id",
      //   type: "hidden",
      // },
    ],
    educationData: [
      {
        value: "",
        name: "enter_order",
        placeholder: "85-BM(1)",
        required: true,
        last: 1,
        label:
          "O'qishga kirgan, o'qishini ko'chirgan va tiklagan talabalar buyrug'i raqami",
      },
      {
        value: "",
        name: "enter_date",
        required: true,
        type: "date",
        label:
          "O'qishga kirgan, o'qishini ko'chirgan va tiklagan talabalar buyrug'ining sanasi",
      },
      {
        value: "",
        name: "enter_comment",
        label: "Izoh",
        type: "select",
        options: enter_comments,
      },
      {
        value: "",
        name: "leave_order",
        placeholder: "85-BM(6)",
        label: "Talabalar safidan chiqarilgan buyrug'i",
        require: true,
        last: 6
      },
      {
        value: "",
        name: "leave_date",
        type: "date",
        label: "Talaba safidan chiqarilgan sanasi oyi yili",
      },
      {
        value: "",
        last: 12,
        name: "academic_holiday",
        label: "Talaba akademik ta'til berilgan buyrug'i",
      },
      {
        value: "",
        name: "academic_holiday_date",
        type: "date",
        label: "Talabalar akademik ta'til berilgan sanasi oyi yili",
      },
      {
        value: "",
        name: "first_to_second",
        last: 2,
        label: "Talaba 1-kursdan 2-kursga o'tish buyrug'i",
      },
      {
        value: "",
        name: "first_to_second_date",
        type: "date",
        label: "Talaba 1-kursdan 2-kursga o'tish sanasi oyi yili",
      },
      {
        value: "",
        name: "second_to_third",
        last: 2,
        label: "Talaba 2-kursdan 3-kursga o'tish buyrug'i",
      },
      {
        value: "",
        name: "second_to_third_date",
        type: "date",
        label: "Talaba 2-kursdan 3-kursga o'tish buyrug'i sanasi oyi yili",
      },
      {
        value: "",
        last: 2,
        name: "third_to_fourth",
        label: "Talaba 3-kursdan 4-kursga o'tish buyrug'i",
      },
      {
        value: "",
        name: "third_to_fourth_date",
        type: "date",
        label: "Talaba 3-kursdan 4-kursga o'tish buyrug'i sanasi oyi yili",
      },
      {
        value: "",
        name: "stay_command",
        last: 4,
        label: "Talaba kursdan kursga qolish buyrug'i",
      },
      {
        value: "",
        name: "stay_date",
        type: "date",
        label: "Talaba kursdan kursga qolish sanasi",
      },
      {
        value: "",
        name: "sorry_command",
        label: "Talabaga hayfsan berilgan buyrug'i",
        // pattern: "([0-9]{2}|[0-9]{3})-BM\(9\)",
        last: 9,
      },
      {
        value: "",
        name: "sorry_command_date",
        type: "date",
        label: "Talabaga hayfsan berilgan sanasi",
      },
      {
        value: "",
        name: "finish_command",
        // pattern: "([0-9]{2}|[0-9]{3})-BM\(8\)",
        last: 8,
        label: "Universitetni tamomlash haqida buyrugi",
      },
      {
        value: "",
        name: "finish_command_date",
        type: "date",
        label: "Universitetni tamomlash haqida buyrugi sanasi",
      },
      {
        value: "",
        name: "finish_index",
        label: "Tamomlash INDEX",
      },
      {
        value: "",
        name: "finish_comment",
        label: "Izoh",
      },
    ],
    place_information: [
      {
        value: "",
        name: "country",
        label: "Davlat",
        required: false,
      },
      {
        value: "",
        name: "province",
        label: "Viloyat",
        required: false,
      },
      {
        value: "",
        name: "disctrict",
        label: "Tuman(shahar)",
        required: false,
      },
      {
        value: "",
        name: "residintial_adress",
        label: "Doimiy yashash Manzili",
        required: false,
      },
      {
        value: "",
        name: "accommodation",
        label: "Vaqtinchalik yashash joyi",
        required: false,
      },
    ],
    additional_infromation: [
      {
        type: "checkbox",
        value: "",
        name: "iron_notebook",
        label_check: "Temir daftari",
      },
      {
        type: "checkbox",
        value: "",
        name: "woman_notebook",
        label_check: "Ayollar daftari",
      },
      {
        type: "checkbox",
        value: "",
        name: "youth_notebook",
        label_check: "Yoshlar daftari",
      },
      {
        type: "checkbox",
        value: "",
        name: "orphan",
        label_check: "Davlat qaramog'ida (chin yetim)",
      },
      {
        type: "checkbox",
        value: "",
        name: "amputatuion",
        label_check:
          "Nogironligi mavjud bo‘lgan shaxslar uchun ajratilgan qo‘shimcha kvota",
      },
      {
        type: "checkbox",
        value: "",
        name: "ligota",
        label_check:
          "Muddatli harbiy xizmatni o‘tab harbiy qism qo‘mondonligi tavsiyanomasiga ega",
      },
      {
        type: "checkbox",
        value: "",
        name: "iab_child",
        label_check:
          "Ichki ishlar organlari xodimlari farzandlari uchun ajratilgan qo‘shimcha kvota",
      },
      {
        type: "checkbox",
        value: "",
        name: "military_child",
        label_check:
          "Qurolli Kuchlari xodimlarining farzandlari uchun ajratilgan qo‘shimcha kvota",
      },
      {
        type: "checkbox",
        value: "",
        name: "desert",
        label_check: "Olis cho‘l tumanlari uchun ajratilgan qo‘shimcha kvota",
      },
      {
        type: "checkbox",
        value: "",
        name: "woman",
        label_check:
          "Xotin-qizlarni qo‘llab-quvvatlash maqsadida berilgan tavsiyanoma asosida qabul",
      },
      {
        type: "checkbox",
        value: "",
        name: "purpose",
        label_check: "Maqsadli qabul",
      },
    ],
    lang: [
      {
        type: "checkbox",
        value: "",
        name: "eng",
        label_check: "Ingliz",
      },
      {
        type: "checkbox",
        value: "",
        name: "ru",
        label_check: "Rus",
      },
      {
        type: "checkbox",
        value: "",
        name: "ar",
        label_check: "Arab",
      },
      {
        type: "checkbox",
        value: "",
        name: "tr",
        label_check: "Turk",
      },
      {
        type: "checkbox",
        value: "",
        name: "kr",
        label_check: "Kores",
      },
      {
        type: "checkbox",
        value: "",
        name: "ya",
        label_check: "Yapon",
      },
      {
        type: "checkbox",
        value: "",
        name: "gr",
        label_check: "Nemis",
      },
      {
        type: "checkbox",
        value: "",
        name: "is",
        label_check: "Ispan",
      },
    ],
    achievements: [
      {
        value: "",
        name: "lang_certificate",
        label: "Chet tilidan til sertifikati",
        placeholder: "IELTS 6,5. 2020-yil",
      },
      {
        value: "",
        name: "champion",
        label: "Jahon va respublika  miqyosidagi musobaqalar sovrindori",
        placeholder: "Mukofat raqami, qayerdan olgani va turi",
      },
      {
        value: "",
        name: "scholarship",
        label: "Davlat mukofotlari yoki stipendiyasi ",
        placeholder: "Alisher Navoiy nomidagi davlat stipendiyasi, 2021-yil",
      },
    ],
    finished: [
      {
        name: "finished",
        value: "",
        label: "Tamomlagan muassasasi",
        placeholder:
          "2012-2015, Urgut shahar Samarqand Davlat unversiteti qoshidagi 3-son akademik litseyi, В№783098",
        alert:
          "Misol:  2012-2015, Urgut shahar Samarqand Davlat unversiteti qoshidagi 3-son akademik litseyi, В№783098 shu shablodagiday vergul bilan ajratib to'liq kiriting",
        required: false,
      },
    ],
    reference: [
      {
        type: "checkbox",
        value: false,
        is_checked: false,
        name: "is_marred",
        label_check: "Turmush qurganmi",
      },
      {
        name: "partisanship",
        value: "yo'q",
        label: "Partiyaviyligi",
        required: false,
      },
      {
        name: "information",
        value: "",
        placeholder: "Oliy",
        label: "Ma'lumoti",
        required: false,
      },
      {
        name: "specality",
        value: "",
        placeholder: "O'rta-maxsus",
        label: "Mutaxassisligi bo'yicha ma'lumoti",
        required: false,
      },
      {
        name: "academic_degree",
        value: "Yo'q",
        placeholder: "Yo'q",
        label: "Ilmiy daraja",
        required: false,
      },
      {
        name: "academic_title",
        value: "Yo'q",
        placeholder: "Yo'q",
        label: "Ilmiy unvoni",
        required: false,
      },
    ],
    relation: [],
    work: [],
  },
  errorMsg: "",
};

import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { useState } from "react";
import { useEffect } from "react";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};
const labels = ["Talabalar"];

export default function LineChart() {
  const [totalDepartment, setTotalDepartment] = useState({
    labels,
    datasets: [],
  });
  const statisticsQuery = {
    math: {
      educational_information: {
        department_id: 1,
      },
    },
    philology: {
      educational_information: {
        department_id: 12,
      },
    },
    physics: {
      educational_information: {
        department_id: 131,
      },
    },
    biology: {
      educational_information: {
        department_id: 6,
      },
    },
    history: {
      educational_information: {
        department_id: 10,
      },
    },
    chemistry: {
      educational_information: {
        department_id: 15,
      },
    },
    economy: {
      educational_information: {
        department_id: 19,
      },
    },
    agro: {
      educational_information: {
        department_id: 132,
      },
    },
    international: {
      educational_information: {
        department_id: 20,
      },
    },
    out: {
      educational_information: {
        department_id: 99,
      },
    },
    beginner: {
      educational_information: {
        department_id: 16,
      },
    },
    legal: {
      educational_information: {
        department_id: 14,
      },
    },
    it: {
      educational_information: {
        department_id: 13,
      },
    },
    sport: {
      educational_information: {
        department_id: 9,
      },
    },
    psychology: {
      educational_information: {
        department_id: 8,
      },
    },
    geography: {
      educational_information: {
        department_id: 7,
      },
    },
    magist: {
      educational_information: {
        department_id: 145,
      },
    },
  };

  useEffect(() => {
    axios.post("/statistics/students", statisticsQuery).then((res) => {
      setTotalDepartment({
        ...totalDepartment,
        datasets: [
          // {
          //   label: "Sirtqi(maxsus sirtqi) bo'limi",
          //   data: [res.data.out],
          //   backgroundColor: "#133863",
          // },
          {
            label: "Filologiya fakulteti",
            data: [res.data.philology],
            backgroundColor: "#133863",
          },
          {
            label: "Matematika fakulteti",
            data: [res.data.math],
            borderColor: "#133863",
            backgroundColor: "#133863",
          },
          {
            label: "Sport faoliyati va san'at fakulteti",
            data: [res.data.sport],
            backgroundColor: "#133863",
          },
          {
            label: "Tarix fakulteti",
            data: [res.data.history],
            borderColor: "#133863",
            backgroundColor: "#133863",
          },
          {
            label: "Maktabgacha va boshlang'ich ta'lim fakulteti",
            data: [res.data.beginner],
            backgroundColor: "#133863",
          },
          {
            label: "Biologiya fakuteti",
            data: [res.data.biology],
            borderColor: "#133863",
            backgroundColor: "#133863",
          },
          {
            label: "Kimyo fakulteti",
            data: [res.data.chemistry],
            borderColor: "#133863",
            backgroundColor: "#133863",
          },
          {
            label: "Muhandislik fizika instituti",
            data: [res.data.physics],
            borderColor: "#133863",
            backgroundColor: "#133863",
          },
          {
            label: "Psixologiya va ijtimoiy-siyosiy fanlar fakulteti",
            data: [res.data.psychology],
            backgroundColor: "#133863",
          },
          {
            label: "Magistratura markazi",
            data: [res.data.magist],
            backgroundColor: "#133863",
          },
          {
            label: "Geografiya va ekologiya fakulteti",
            data: [res.data.geography],
            backgroundColor: "#133863",
          },

          {
            label: "Yuridik fakulteti",
            data: [res.data.legal],
            backgroundColor: "#133863",
          },

          {
            label: "Agrobiotexnologiyalar va oziq-ovqat xavfsizligi instituti",
            data: [res.data.agro],
            backgroundColor: "#133863",
          },

          {
            label:
              "Intellektual tizimlar va kompyuter texnologiyalari fakulteti",
            data: [res.data.it],
            backgroundColor: "#133863",
          },
          {
            label: "Inson resurslari fakulteti",
            data: [res.data.economy],
            borderColor: "#133863",
            backgroundColor: "#133863",
          },
          {
            label: "Xalqaro ta’lim dasturlari markazi",
            data: [res.data.international],
            backgroundColor: "#133863",
          },



        ],

      });
    });
  }, []);
  return <Bar options={options} data={totalDepartment} />;
}

import React from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { handleChange } from '../../store/Slices/studentSlice/studentSlice'

export default function Checkbox({ index, name, type, section, is_disable, label_check, value }) {
    const dispatch = useDispatch();
    return (
        <div>
            <Form.Check
                inline
                data-type={section}
                data-index={index}
                disabled={is_disable ? true : false}
                type={type}
                label={label_check}
                name={name}
                checked={value}
                onChange={(e) => dispatch(handleChange({ type: e.target.dataset.type, index: e.target.dataset.index, value: e.target.checked }))}
            />
        </div>
    )
}

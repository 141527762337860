import React from 'react'
import AddStudent from './AddStudent'

function Add({ expected, title }) {
    return (
        <div className='mx-5'>
            <h2 className='section fw-bold'>{title}</h2>
            <AddStudent expected={expected} />
        </div >
    )
}

export default Add
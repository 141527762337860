import axios from "axios";
import { useState, createContext, useContext } from "react";
import { useCookies } from "react-cookie";
const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [cookie, setCookie, removeCookie] = useCookies(null);
  const [loading, setLoading] = useState(false)

  // check user is auth or not
  const [user, setUser] = useState(() => {
    let userProfle = cookie.loo;
    if (userProfle) {
      return true;
    }
    return null;
  });

  // User permissopns actions
  const [perms, setPerms] = useState(() => {
    let perm = JSON.parse(localStorage.getItem("_perms"));
    if (perm) {
      return perm;
    }
    return [];
  });
  var now = new Date();
  var time = now.getTime();
  var expireTime = time + 1000 * 36000;
  let exp = new Date(expireTime);
  const login = (user, ada, setErrorState) => {
    setLoading(true)
    axios
      .post("login", user)
      .then((res) => {
        setCookie("loo", res.data.data.token, { path: "/", expires: exp });
        setCookie("_role", res.data.data.role.role, { path: "/", expires: exp });

        localStorage.setItem('fullname', res.data.data.user.full_name)
        localStorage.setItem(
          "_perms",
          JSON.stringify(res.data.data.permissions)
        );

        /* Agar fakultet bulsa fakultet id si aks xolda tyutor idsini yozish */


        console.log(res.data);

        if (res.data.data.role.role === "dean" || res.data.data.role.role === "tutor")

          setCookie("department_id", res.data.data.department.id, {
            path: "/",
            expires: exp
          });

        setUser(true);
        // setPerms(res.data.data.permissions);
        window.location.reload();
        // navigate("/")
        setLoading(false)
      })
      .catch((err) => {
        console.log(err);
        // setErrorState(err.response.status)
        setLoading(false)
      });
  };

  const logOut = () => {
    removeCookie("loo", { path: "/" });
    removeCookie("department_id", { path: "/" });
    removeCookie("_role", { path: "/" });
    setUser(null);
    setPerms([]);
    localStorage.removeItem("_perms");
  };

  return (
    <AuthContext.Provider value={{ user, loading, perms, login, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

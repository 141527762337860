import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import LineChart from '../components/Charts/LineChart'
import axios from 'axios'
import { Link } from 'react-router-dom'
function Home() {
    const statisticsQuery = {
        "iron_notebook": {
            "additional_information": {
                "iron_notebook": 1
            }
        },
        "youth_notebook": {
            "additional_information": {
                "youth_notebook": 1
            }
        },
        "orphan": {
            "additional_information": {
                "orphan": 1
            }
        },
        "amputatuion": {
            "additional_information": {
                "amputatuion": 1
            }
        },
        "woman": {
            "additional_information": {
                "woman": 1
            }
        },
        "purpose": {
            "additional_information": {
                "purpose": 1
            }
        },
        "all": {
            "students[active]": true
        }
    }
    const [statistics, setStatistics] = useState({});
    useEffect(() => {
        axios.post("/statistics/students", statisticsQuery).then(res => {
            setStatistics(res.data)
        })

    }, [])
    return (
        <div className='container statistics'>
            <Row sm={1} xl={3}>
                <Col>
                    <Link to="/students">
                        <div className="section bg1 statistics-item mb-3">
                            <p className='stat-title'>Jami talabalar: </p>
                            <p className='stat-number'>{statistics.all}</p>
                        </div>
                    </Link>
                </Col>
                <Col>
                    <Link to="/students?additional_information%5Biron_notebook%5D=1">
                        <div className="section bg2 statistics-item mb-3">
                            <p className='stat-title'>Temir daftarda turadigan talabalar: </p>
                            <p className='stat-number'>{statistics.iron_notebook}</p>
                        </div>
                    </Link>
                </Col>
                <Col>
                    <Link to="/students?additional_information%5Byouth_notebook%5D=1">
                        <div className="section bg6 statistics-item mb-3">
                            <p className='stat-title'>Yoshlar daftari: </p>
                            <p className='stat-number'>{statistics.youth_notebook}</p>
                        </div>
                    </Link>
                </Col>
                <Col>
                    <Link to="/students?additional_information%5Bamputatuion%5D=1">
                        <div className="section bg3 statistics-item mb-3">
                            <p className='stat-title'>Nogironligi mavjud bo‘lgan shaxslar uchun ajratilgan qo‘shimcha kvota: </p>
                            <p className='stat-number'>{statistics.amputatuion}</p>
                        </div>
                    </Link>
                </Col>

                <Col>
                    <Link to="/students?additional_information%5Bwoman%5D=1">
                        <div className="section bg4 statistics-item mb-3">
                            <p className='stat-title'>Xotin-qizlarni qo‘llab-quvvatlash maqsadida berilgan tavsiyanoma asosida qabul: </p>
                            <p className='stat-number'>{statistics.woman}</p>
                        </div>
                    </Link>
                </Col>
                <Col>
                    <Link to="/students?additional_information%5Bwoman%5D=1">
                        <div className="section bg5 statistics-item mb-3">
                            <p className='stat-title'>Maqsadli qabul: </p>
                            <p className='stat-number'>{statistics.purpose}</p>
                        </div>
                    </Link>
                </Col>
            </Row>

            <div className="section">
                <h2>Fakultet talabalari diagramma hisobida:</h2>
                <LineChart />
            </div>
        </div>
    )
}

export default Home
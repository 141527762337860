export default function serializeFormQuery(text) {
  let query = {};
  Object.keys(text).forEach((item) => {
    if (item === "study_informations") {
      Object.keys(text[item]).forEach((element) => {
        if (text[item][element].length !== 0 && text[item][element]) {
          if (element.includes("_date")) {
            if (!!text[item][element]['begin'] || !!text[item][element]['end']) {
              if (!!text[item][element]['begin'] && !!text[item][element]['end']) {

                query[`${item}[dates][${element}][beginDate]`] = `${text[item][element]['begin']}`;
                query[`${item}[dates][${element}][endDate]`] = `${text[item][element]['end']}`;

              } else if (!!text[item][element]['begin']) {

                query[`${item}[dates][${element}][beginDate]`] = `${text[item][element]['begin']}`;
                query[`${item}[dates][${element}][endDate]`] = getTuday();

              } else if (!!text[item][element]['end']) {

                query[`${item}[dates][${element}][endDate]`] = `${text[item][element]['end']}`;
                query[`${item}[dates][${element}][beginDate]`] = `${"2019-01-01"}`;

              }
            }
          } else
            query[`${item}[commands][${element}]`] = `${text[item][element]}`;
        }
      });
    }
    else
      Object.keys(text[item]).forEach((element) => {
        if (text[item][element] === true) {
          query[`${item}[${element}]`] = 1;
        } else if (text[item][element].length !== 0 && text[item][element])
          query[`${item}[${element}]`] = `${text[item][element]}`;
      });
  });
  return query;
}

function getTuday() {


  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();

  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;

  return yyyy + '-' + mm + '-' + dd;
}

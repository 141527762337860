export const userRoles = {
  super_admin: "super_admin",
  student_staff: "student_staff",
  rector: "rector",
  dean: "dean",
  tutor: "tutor",
};

export const URL_IMG = "https://info.samdu.uz/";
// export const URL_IMG = "http://172.16.8.182:8000/images/students/";

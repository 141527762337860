import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth } from './Auth'
import MenuBar from '../components/Menu';
import { menuToggle } from '../store/Slices/menuSlice';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
function ProtectedRoute({ expectedRoles }) {
    const [cookie] = useCookies()
    const menu = useSelector((state) => state.menu);
    const dispatch = useDispatch()
    const location = useLocation()

    /* Axios default request paramets with auth token */
    axios.interceptors.request.use(
        (request) => {
            request.headers[
                "Authorization"
            ] = `Bearer ${cookie.loo}`;
            return request;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    /* Check user Authorized or noAuthorized */
    const auth = useAuth();
    let isAuthorized = auth.user;

    if (!isAuthorized) {
        return <Navigate to="/login" state={{ path: location.pathname }} />;
    }

    return (
        <>
            <MenuBar />
            <div className={`main ${menu.isOpen && "menu-open"}`}>
                <header className='d-flex justify-content-between'>
                    <button id="main-menu-button" className="core-properties colour" onClick={() => dispatch(menuToggle())}>
                        <div>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                    <p>{localStorage.getItem("fullname")}</p>
                </header>
                <div className='p-3 position-relative'>
                    <ToastContainer position="bottom-right" />
                    <Outlet />
                </div>
            </div>
        </>
    )
}

export default ProtectedRoute
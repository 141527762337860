import { toast } from "react-toastify";
import CreatePdf from "./Createpdf";

export default function makePdfData(data) {
  if (data?.student_relative?.relatives) {
    const specialData = JSON.parse(data?.student_relative?.relatives);
    const pdfArray = [];

    function dotDate(old_date, yy) {
      const date = new Date(old_date);
      const yyyy = date.getFullYear();
      let mm = date.getMonth() + 1; // Months start at 0!
      let dd = date.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      if (yy) return yyyy;

      return dd + "." + mm + "." + yyyy;
    }

    const arrBody = [
      [
        {
          title: "JSHSHIR:",
          text: data.JSHSHIR,
        },
      ],
      [
        {
          title: "Tug'ilgan yili:",
          text: dotDate(data.birthday),
        },
        {
          title: "Tug'ilgan joyi:",
          text: data.accomodation?.province + " " + data.accomodation.disctrict,
        },
      ],
      [
        {
          title: "Millati",
          text: data.nationality,
        },
        {
          title: "Partiyaviyligi:",
          text: specialData.partisanship,
        },
      ],
      [
        {
          title: "Ma'lumoti:",
          text: +data.educational_information.department === 145 ? "Oliy" : "Tugallanmagan oliy",
        },
        // {
        //   title: "Tamomlagan:",
        //   text: data.graduate.finish_collage || data.graduate.finish_university,
        // },
      ],
      [
        {
          line: "one",
          title: "Ma'lumoti bo'yicha mutaxassisligi:",
        },
        {
          title: data.educational_information.group.specialty.specialty,
        },
      ],
      [
        {
          title: "Ilmiy daraja:",
          text: specialData.academic_degree,
        },
        {
          title: "Ilmiy unvoni:",
          text: specialData.academic_title,
        },
      ],
      [
        {
          title: "Qaysi chet tilini biladi",
          text: specialData.lang || "Yo'q",
        },
      ],
      [
        {
          title: "Davlat mukofotlari bilan taqdirlanganmi (qanaqa):",
          text: data.achievement.scholarship || "Yo'q",
        },
      ],
    ];
    const relations = specialData.relation.map((relation) => [
      relation.who,
      relation.full_name,
      relation.birthday,
      relation.work,
      relation.place,
    ]);
    pdfArray.push({
      name: "text",
      size: 14,
      height: 1.5,
      text: data?.full_name,
      align: "center",
      weight: "bold",
    });
    if (data?.study_information?.enter_date) {
      pdfArray.push({
        name: "text",
        height: 1,
        text: dotDate(data?.study_information?.enter_date) + " yildan:",
        align: "left",
        weight: "normal",
      });
      pdfArray.push({
        name: "text",
        width: "1.2",
        text:
          "Sharof Rashidov nomidagi Samarqand davlat universiteti " +
          data.educational_information.department.name +
          " talabasi",
        align: "left",
        weight: "bold",
        height: 1,
      });
    }

    pdfArray.push({
      name: "line",
      body: {
        body: arrBody,
      },
    });
    pdfArray.push({
      name: "text",
      text: "MEHNAT FAOLIYATI",
      align: "center",
      size: 16,
      weight: "bold",
      height: 1.5,
    });

    specialData.work.forEach((element) => {
      let end = element.end === "-" ? "h.v" : element.end;
      pdfArray.push({
        name: "work",
        text1: element.begin + " - " + end,
        text2: element.work,
      });
    });
    if (data?.study_information?.enter_date)
      pdfArray.push({
        name: "work",
        text1:
          dotDate(data?.study_information.enter_date, true) + " - " + "h.v",
        text2:
          "Sharof Rashidov nomidagi Samarqand davlat universiteti " +
          data.educational_information.department.name +
          " talabasi",
      });

    pdfArray.push({
      name: "text",
      height: 1.5,
      page: true,
      size: 14,
      text: data?.full_name + " ning yaqin qarindoshlari haqida",
      align: "center",
      weight: "bold",
    });
    pdfArray.push({
      name: "text",
      size: 16,
      text: "MA'LUMOT",
      align: "center",
      weight: "bold",
      height: 1,
    });

    pdfArray.push({
      name: "table",
      body: relations,
    });
    console.log(data?.image?.ImagePath);

    CreatePdf(
      pdfArray,
      data.student_id_number,
      data?.full_name,
      data?.image?.ImagePath
    );
  } else {
    toast.error("Ma'lumotlar to'liq yuklanmagan");
  }
}

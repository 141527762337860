import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
  isOpen: true,
  exit: true,
};

export const menu = createSlice({
  name: "menuState",
  initialState: defaultState,
  reducers: {
    menuToggle: (state) => {
      state.isOpen = !state.isOpen;
    },
    exitToggle: (state) => {
      state.exit = !state.exit;
    },
  },
});

export const { menuToggle, exitToggle } = menu.actions;
export default menu.reducer;

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./routes/Auth";
import RouterRender from "./routes/router-render";
import { CookiesProvider } from "react-cookie";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";

axios.defaults.baseURL = "https://info.samdu.uz/api";
// axios.defaults.baseURL = "http://172.16.8.91:8000/api";
axios.defaults.headers.post["Accept"] = "application/json";

function App() {
  return (
    <CookiesProvider>
      <AuthProvider>
        <RouterRender />
      </AuthProvider>
    </CookiesProvider>
  );
}

export default App;

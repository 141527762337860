import React from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { addRelation, editRelation, removeRelation } from '../../store/Slices/studentSlice/studentSlice';

function PersonalInfo() {
    const dispatch = useDispatch();
    const relation = useSelector(state => state.student.body.relation)

    const relationArr = [
        "Otasi",
        "Onasi",
        "Turmush o'rtog'i",
        "Qaynotasi",
        "Qaynonasi",
        "Opasi",
        "Akasi",
        "Singlisi",
        "Ukasi",
        "Farzandi",
    ]
    return (
        <Card>
            <Card.Header className='bg-primary text-white'>Yaqin qarindoshlar haqida ma'lumotlar</Card.Header>
            <Card.Body>
                {
                    relation.map((item, index) => (
                        <Row key={index} className='align-items-center'>
                            <Col xs={2} className='my-3'>
                                <Form.Label>Qarindoshligi</Form.Label>
                                <Form.Select value={item.who} required data-index={index} onChange={(e) => dispatch(editRelation({ name: "who", index: e.target.dataset.index, value: e.target.value }))}>
                                    <option value="">Tanlang</option>
                                    {
                                        relationArr.map(relation => (
                                            <option key={relation} value={relation}>{relation}</option>

                                        ))
                                    }
                                </Form.Select>
                            </Col>
                            <Col xs={3} className='my-3'>
                                <Form.Label>Ismi, Familyasi, Otasining ismi </Form.Label>
                                <Form.Control
                                    onChange={(e) => dispatch(editRelation({ name: "full_name", index: e.target.dataset.index, value: e.target.value }))}
                                    required
                                    data-index={index}
                                    type={"text"}
                                    value={item.full_name}
                                />
                            </Col>
                            <Col xs={2} className='my-3'>
                                <Form.Label>Tugʼilgan yili va joyi</Form.Label>
                                <Form.Control
                                    onChange={(e) => dispatch(editRelation({ name: "birthday", index: e.target.dataset.index, value: e.target.value }))}
                                    required
                                    data-index={index}
                                    type={"text"}
                                    value={item.birthday}
                                />
                            </Col>
                            <Col xs={2} className='my-3'>
                                <Form.Label>Ish joyi va lavozimi</Form.Label>
                                <Form.Control
                                    onChange={(e) => dispatch(editRelation({ name: "work", index: e.target.dataset.index, value: e.target.value }))}
                                    required
                                    data-index={index}
                                    type={"text"}
                                    value={item.work}
                                />
                            </Col>
                            <Col xs={2} className='my-3'>
                                <Form.Label>Turar joyi</Form.Label>
                                <Form.Control
                                    onChange={(e) => dispatch(editRelation({ name: "place", index: e.target.dataset.index, value: e.target.value }))}
                                    required
                                    data-index={index}
                                    type={"text"}
                                    value={item.place}
                                />
                            </Col>
                            <Col xs={1}>
                                <Button className='my-3' onClick={() => dispatch(removeRelation(index))} variant='danger'>O'chirish</Button>
                            </Col>
                        </Row>

                    ))
                }
                <Button onClick={() => dispatch(addRelation("otasi"))}>qo'shish</Button>
            </Card.Body>
        </Card>
    )
}

export default PersonalInfo
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Col, Button, Row, Form, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "../components/Form/Checkbox";
import InputFeild from "../components/Form/Input";
import MySelect from "../components/Form/Select";
import WorksForm from "../components/Form/WorksForm";
import PersonalInfo from "../components/Form/PersonalInfo";
import {
    defaultState,
    fetchUser,
} from "../store/Slices/studentSlice/studentSlice";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import { toast } from "react-toastify";
import { URL_IMG, URL_Loacal } from "../routes/constants";

function AddStudent({ expected }) {
    const [validated, setValidated] = useState(false);
    const { id } = useParams();
    const toastId = useRef(null);

    const inputs = useSelector((state) => state.student);
    const dispatch = useDispatch();
    const [img, setImg] = useState("");

    const navigate = useNavigate("");

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();
            toastId.current = toast.loading("Please wait...", {
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });

            let data = {
                student_information: {},
                educational_information: {},
                study_information: {},
                additional_information: {},
                graduate: {},
                accomodation: {},
                achievements: {},
                relatives: {},
                lang: "",
            };

            inputs.body.passport.forEach((item) => {
                data["student_information"][item.name] = item.value;
            });

            inputs.body.education.forEach((item) => {
                data["educational_information"][item.name] = item.value;
            });

            inputs.body.educationData.forEach((item) => {
                data["study_information"][item.name] = item.value.length
                    ? item.value
                    : null;
            });

            inputs.body.finished.forEach((item) => {
                data["graduate"][item.name] = item.value;
            });

            inputs.body.place_information.forEach((item) => {
                data["accomodation"][item.name] = item.value;
            });

            inputs.body.achievements.forEach((item) => {
                data["achievements"][item.name] = item.value;
            });

            inputs.body.additional_infromation.forEach((item) => {
                data["additional_information"][item.name] = item.value ? 1 : null;
            });
            let relatives = {};
            inputs.body.reference.forEach((item, index) => {
                if (index === 0) {
                    data["relatives"]["is_married"] = item.value ? 1 : 0;
                } else {
                    relatives[item.name] = item.value;
                }
            });
            inputs.body.lang.forEach((item, index) => {
                if (item.value) {
                    data["lang"] += item.name + ",";
                }
            });
            data.relatives["relatives"] = JSON.stringify({
                relation: inputs.body.relation,
                work: inputs.body.work,
                ...relatives,
            });

            axios
                .post(`/students/${id}/settings`, {
                    ...data,
                    _method: "PUT",
                })
                .then((res) => {
                    dispatch(defaultState());
                    setValidated(false);
                    const formData = new FormData();
                    formData.append("image", img);
                    if (img) {
                        img &&
                            axios.post(`/students/${id}/image`, formData).then((res) => {
                                toast.update(toastId.current, {
                                    render: "Ma'lumot saqlandi",
                                    type: "success",
                                    autoClose: 2000,
                                    isLoading: false,
                                });
                                navigate("/students/" + id);
                            });
                    } else {
                        toast.update(toastId.current, {
                            render: "Ma'lumot saqlandi",
                            type: "success",
                            autoClose: 2000,
                            isLoading: false,
                        });
                        navigate("/students/" + id);
                    }
                })
                .catch((err) => {
                    toast.update(toastId.current, {
                        render: err?.response?.data?.message,
                        type: "error",
                        autoClose: 2000,
                        isLoading: false,
                    });
                });
        }

        setValidated(true);
    };

    function handleImgChange(event) {
        if (event.target.files && event.target.files.length > 0)
            setImg(event.target.files[0]);
    }

    useEffect(() => {
        dispatch(fetchUser(id));
        return () => {
            dispatch(defaultState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (inputs.errorMsg.length !== 0) {
            toast.warn(inputs.errorMsg);
            dispatch(defaultState(true));
        }
    }, [inputs.loading]);

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Loader loading={inputs.loading} />


            {expected.map((section, index) => {

                return section === "relation" ? (
                    <PersonalInfo key={index} />
                ) : section === "work" ? (
                    <WorksForm key={index} />
                ) : (
                    <Card className="mb-4" key={index}>
                        <Card.Header className="bg-primary text-white"> </Card.Header>
                        <Card.Body>
                            <Row>
                                {inputs.body[section].map((item, index) => (
                                    <Col
                                        xs={1}
                                        md={2}
                                        lg={item.name === "finished" ? 12 : 4}
                                        key={item.name}
                                        className="mb-3"
                                    >
                                        <Form.Group controlId={item.name}>
                                            <Form.Label>{item.label}</Form.Label>
                                            {item.type === "select" ? (
                                                <MySelect
                                                    section={section}
                                                    index={index}
                                                    options={item.options}
                                                    required={item.required}
                                                />
                                            ) : item.type === "file" ? (
                                                <>
                                                    <Form.Control
                                                        onChange={handleImgChange}
                                                        required={item.value ? false : true}
                                                        type="file"
                                                        accept="image/*"
                                                    />
                                                    {(img || item.value) && (
                                                        <div className="mt-3">
                                                            <img
                                                                className="user_img"
                                                                src={
                                                                    img
                                                                        ? URL.createObjectURL(img)
                                                                        : `${URL_IMG}/${item.value}`
                                                                }
                                                                alt="11"
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            ) : item.type === "radio" || item.type === "checkbox" ? (
                                                <Checkbox
                                                    index={index}
                                                    section={section}
                                                    name={item.name}
                                                    is_disable={item.disabled}
                                                    type={item.type}
                                                    label_check={item.label_check}
                                                    value={item.value}
                                                />
                                            ) : (
                                                <InputFeild
                                                    section={section}
                                                    item={item}
                                                    index={index}
                                                />
                                            )}
                                        </Form.Group>
                                    </Col>
                                ))}
                            </Row>
                        </Card.Body>
                    </Card>
                );
            })}
            <div className="d-flex justify-content-end">
                <Button type="submit">Formani yuborish</Button>
            </div>
            {/* <ToastContainer position="bottom-right" /> */}
        </Form>
    );
}

export default AddStudent;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { defaultData } from "./initialStates";

// function formatDate(date) {
//   const today = new Date(date * 1000);
//   const yyyy = today.getFullYear();
//   let mm = today.getMonth() + 1; // Months start at 0!
//   let dd = today.getDate();

//   if (dd < 10) dd = "0" + dd;
//   if (mm < 10) mm = "0" + mm;

//   return yyyy + "-" + mm + "-" + dd;
// }

export const fetchUser = createAsyncThunk("student/getStudent", async (id) => {
  return axios.get(`check/students/${id}`).then((res) => res.data.data.student);
});

export const passportSlice = createSlice({
  name: "student",
  initialState: defaultData,
  reducers: {
    handleBlur(state, actions) {
      if (
        actions.payload.value.length !== 0 &&
        !actions.payload.value.includes("BM") &&
        actions.payload.type === "educationData"
      ) {
        switch (actions.payload.index) {
          case "0": {
            if (actions.payload.value.includes("-13"))
              state.body[actions.payload.type][actions.payload.index].value =
                actions.payload.value.slice(
                  0,
                  actions.payload.value.length - 3
                ) + "-BM(13)";
            else
              state.body[actions.payload.type][actions.payload.index].value +=
                "-BM(1)";
            break;
          }
          case "3": {
            state.body[actions.payload.type][actions.payload.index].value +=
              "-BM(6)";
            break;
          }
          case "5": {
            state.body[actions.payload.type][actions.payload.index].value +=
              "-BM(12)";
            break;
          }
          case "7":
          case "9":
          case "11": {
            state.body[actions.payload.type][actions.payload.index].value +=
              "-BM(2)";
            break;
          }
          case "13": {
            state.body[actions.payload.type][actions.payload.index].value +=
              "-BM(4)";
            break;
          }
          case "15": {
            state.body[actions.payload.type][actions.payload.index].value +=
              "-BM(9)";
            break;
          }
          case "17": {
            state.body[actions.payload.type][actions.payload.index].value +=
              "-BM(8)";
            break;
          }
          default:
        }
      }
    },
    handleChange(state, actions) {
      state.body[actions.payload.type][actions.payload.index].value =
        actions.payload.value;
    },

    addRelation(state, actions) {
      state.body.relation.push({
        who: actions.payload,
        full_name: "",
        birthday: "",
        work: "",
        place: "",
      });
    },
    addWork(state) {
      state.body.work.push({
        begin: "",
        end: "",
        work: "",
      });
    },
    editRelation(state, actions) {
      state.body.relation[actions.payload.index][actions.payload.name] =
        actions.payload.value;
    },
    removeRelation(state, actions) {
      state.body.relation.splice(actions.payload, 1);
    },
    editWork(state, actions) {
      state.body.work[actions.payload.index][actions.payload.name] =
        actions.payload.value;
    },
    removeWork(state, actions) {
      state.body.work.splice(actions.payload, 1);
    },

    defaultState() {
      return defaultData;
    },
  },

  extraReducers(builder) {
    builder.addCase(fetchUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, actions) => {
      state.loading = false;
      state.body.passport[0].value = actions.payload.full_name;
      state.body.passport[0].disabled = true;
      if (actions?.payload?.phone) {
        state.body.passport[1].value = actions?.payload?.phone;
        // state.body.passport[1].disabled = true;
      }
      if (actions?.payload?.image?.ImagePath) {
        state.body.passport[2].value = actions?.payload?.image?.ImagePath;
        // state.body.passport[2].disabled = true;
      }

      // O'qish ma'lumotlari
      if (actions?.payload?.study_information?.enter_order) {
        state.body.educationData[0].value =
          actions?.payload?.study_information?.enter_order;
      }
      if (actions?.payload?.study_information?.enter_date) {
        state.body.educationData[1].value =
          actions?.payload?.study_information?.enter_date;
      }
      if (actions?.payload?.study_information?.enter_comment) {
        state.body.educationData[2].value =
          actions?.payload?.study_information?.enter_comment;
      }
      if (actions?.payload?.study_information?.leave_order) {
        state.body.educationData[3].value =
          actions?.payload?.study_information?.leave_order;
      }
      if (actions?.payload?.study_information?.leave_date) {
        state.body.educationData[4].value =
          actions?.payload?.study_information?.leave_date;
      }
      if (actions?.payload?.study_information?.academic_holiday) {
        state.body.educationData[5].value =
          actions?.payload?.study_information?.academic_holiday;
      }
      if (actions?.payload?.study_information?.academic_holiday_date) {
        state.body.educationData[6].value =
          actions?.payload?.study_information?.academic_holiday_date;
      }
      if (actions?.payload?.study_information?.first_to_second) {
        state.body.educationData[7].value =
          actions?.payload?.study_information?.first_to_second;
      }
      if (actions?.payload?.study_information?.first_to_second_date) {
        state.body.educationData[8].value =
          actions?.payload?.study_information?.first_to_second_date;
      }
      if (actions?.payload?.study_information?.second_to_third) {
        state.body.educationData[9].value =
          actions?.payload?.study_information?.second_to_third;
      }
      if (actions?.payload?.study_information?.second_to_third_date) {
        state.body.educationData[10].value =
          actions?.payload?.study_information?.second_to_third_date;
      }
      if (actions?.payload?.study_information?.third_to_fourth) {
        state.body.educationData[11].value =
          actions?.payload?.study_information?.third_to_fourth;
      }
      if (actions?.payload?.study_information?.third_to_fourth_date) {
        state.body.educationData[12].value =
          actions?.payload?.study_information?.third_to_fourth_date;
      }
      if (actions?.payload?.study_information?.stay_command) {
        state.body.educationData[13].value =
          actions?.payload?.study_information?.stay_command;
      }
      if (actions?.payload?.study_information?.stay_date) {
        state.body.educationData[14].value =
          actions?.payload?.study_information?.stay_date;
      }
      if (actions?.payload?.study_information?.sorry_command) {
        state.body.educationData[15].value =
          actions?.payload?.study_information?.sorry_command;
      }
      if (actions?.payload?.study_information?.sorry_command_date) {
        state.body.educationData[16].value =
          actions?.payload?.study_information?.sorry_command_date;
      }
      if (actions?.payload?.study_information?.finish_command) {
        state.body.educationData[17].value =
          actions?.payload?.study_information?.finish_command;
      }
      if (actions?.payload?.study_information?.finish_command_date) {
        state.body.educationData[18].value =
          actions?.payload?.study_information?.finish_command_date;
      }
      if (actions?.payload?.study_information?.finish_index) {
        state.body.educationData[19].value =
          actions?.payload?.study_information?.finish_index;
      }
      if (actions?.payload?.study_information?.finish_comment) {
        state.body.educationData[20].value =
          actions?.payload?.study_information?.finish_comment;
      }

      console.log(actions?.payload);
      // Yutuqlari
      if (actions?.payload?.achievement?.lang_certificate) {
        state.body.achievements[0].value =
          actions?.payload?.achievement?.lang_certificate;
      }
      if (actions?.payload?.achievement?.champion) {
        state.body.achievements[1].value =
          actions?.payload?.achievement?.champion;
      }
      if (actions?.payload?.achievement?.scholarship) {
        state.body.achievements[1].value =
          actions?.payload?.achievement?.scholarship;
      }
      // Oilasi
      if (actions.payload?.student_relative?.relatives) {
        state.body.relation = JSON.parse(
          actions.payload.student_relative.relatives
        ).relation;
      }

      // ish joylari
      if (actions.payload?.student_relative?.relatives) {
        state.body.work = JSON.parse(
          actions.payload.student_relative.relatives
        ).work;
      }

      //  Kirish bali
      if (actions?.payload?.educational_information?.mark) {
        state.body.education[0].value =
          actions?.payload?.educational_information?.mark;
      }
      // if (actions?.payload?.image?.ImagePath) {
      //   state.body.passport[2].value = actions?.payload?.image?.ImagePath;
      //   // state.body.passport[2].disabled = true;
      // }
      // if (actions?.payload?.image?.ImagePath) {
      //   state.body.passport[2].value = actions?.payload?.image?.ImagePath;
      //   // state.body.passport[2].disabled = true;
      // }
      // state.body.passport[3].value = actions.payload.third_name;
      // state.body.passport[8].value =
      //   actions.payload.gender.code === "11" ? true : false;
      // state.body.passport[8].disabled = true;
      // state.body.passport[9].value = formatDate(actions.payload.birth_date);
      // state.body.passport[9].disabled = true;
      // state.body.passport[12].value = actions.payload.image;

      // state.body.education[0].value = actions.payload.department.name;
      // state.body.education[0].disabled = true;
      // state.body.education[1].value = actions.payload.specialty.code;
      // state.body.education[1].disabled = true;
      // state.body.education[2].value = actions.payload.specialty.name;
      // state.body.education[2].disabled = true;
      // state.body.education[3].value = actions.payload.group.name;
      // state.body.education[3].disabled = true;
      // state.body.education[4].value = actions.payload.educationForm.name;
      // state.body.education[4].disabled = true;
      // state.body.education[5].value = actions.payload.educationType.name;
      // state.body.education[5].disabled = true;
      // state.body.education[6].value = actions.payload.level.name;
      // state.body.education[6].disabled = true;
      state.body.finished[0].value = actions.payload.graduate.finished;
      // if (actions.payload.graduate) state.body.finished[0].disabled = true;

      if (actions.payload.accomodation.country) {
        state.body.place_information[0].value =
          actions.payload.accomodation.country;
        state.body.place_information[0].disabled = true;
      }

      if (actions.payload.accomodation.province) {
        state.body.place_information[1].value =
          actions.payload.accomodation.province;
        state.body.place_information[1].disabled = true;
      }

      if (actions.payload.accomodation.disctrict) {
        state.body.place_information[2].value =
          actions.payload.accomodation.disctrict;
        state.body.place_information[2].disabled = true;
      }
      if (actions.payload.accomodation.residintial_adress) {
        state.body.place_information[3].value =
          actions.payload.accomodation.residintial_adress;
        state.body.place_information[3].disabled = true;
      }

      if (actions.payload.accomodation.accommodation) {
        state.body.place_information[4].value =
          actions.payload.accomodation.accommodation;
        state.body.place_information[4].disabled = false;
      }
      state.errorMsg = "";
    });
    builder.addCase(fetchUser.rejected, (state, actions) => {
      state.errorMsg = "Bunday talaba topilmadi";
      state.loading = false;
    });
  },
});

export const {
  handleChange,
  addRelation,
  editRelation,
  removeRelation,
  removeWork,
  editWork,
  addWork,
  handleBlur,
  defaultState,
} = passportSlice.actions;
export default passportSlice.reducer;

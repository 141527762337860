import { configureStore } from "@reduxjs/toolkit";
import findSlice from "./Slices/findSlice/FindSlice";
import menu from "./Slices/menuSlice";
import SendSlice from "./Slices/sendSlice/SendSlice";
import studentSlice from "./Slices/studentSlice/studentSlice";
export const store = configureStore({
  reducer: {
    student: studentSlice,
    menu: menu,
    linkHandler: SendSlice,
    find: findSlice,
  },
});

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';

function DepartmentCompliteList() {
    const [count, setCount] = useState([{
        department: "Filalogiya",
        department_id: 12,
        total: 1563
    }]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setLoading(true);
        axios.get("/statistics/get-update-student-count")
            .then(res => {
                setCount(res.data);
                setLoading(false)
            })
            .catch(err => setLoading(false))
    }, [])
    return (
        <>
            <Loader loading={loading} />
            <div className='section'>
                <Row className='border-bottom pb-2 mb-3'>
                    <Col className='fw-bold'>Jami: {count.all}</Col>
                </Row>
                <Row className='fw-bold border-bottom py-1'>
                    <Col xs={5}>Fakultet nomi</Col>
                    <Col xs={3}>Jami kiritilgan talabalar soni</Col>
                    <Col xs={3}>Jami talabalar soni</Col>
                </Row>
                {
                    count?.data?.map(item => {
                        return (
                            <Row key={item.department_id} className='border-bottom py-1'>
                                <Col xs={5}><Link to={`/statistics/${item.department_id}`}>{item.name}</Link> </Col>
                                <Col xs={3}>{item.update_student_count}</Col>
                                <Col xs={3}>{item.total}</Col>
                            </Row>
                        )
                    })
                }
            </div>
        </>
    )
}

export default DepartmentCompliteList
import axios from 'axios';
import React, { useState } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';

function ChangeUser({ user, setUsers, setLoading, groups, setGroups }) {
    // get student id for delete user

    const initialUser = {
        name: user.name,
        full_name: user.full_name,
        password: "",
        groups: [],
    }
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setUpdateUser({
            ...updateUser,
            password: ""
        })
        setShow(false)
    };




    const [updateUser, setUpdateUser] = useState(initialUser);

    function handleChange(e) {
        setUpdateUser({
            ...updateUser,
            [e.target.name]: e.target.value
        })
    }

    function handleSubmit(e) {
        e.preventDefault()
        let data = {
            ...updateUser,
            groups: updateUser.groups.map(item => item.value)
        }
        if (updateUser.password.length === 0) delete data.password
        axios.post(`tyutors/${user.id}`, {
            "_method": "PUT",
            ...data
        }).then(res => {
            toast.success("Mufaqqiyatli yarildi.");
            handleClose()
            axios.get(`/tyutors`).then(res => {
                setUsers(res.data.data.tyutors)
                setLoading(false)

            }).catch(err => {
                setLoading(false)
            })
            axios.get('groups').then(res => {
                setGroups(res.data.data.groups.map(item => ({ label: item.group, value: item.id })))
            })
        }).catch(err => {
            toast.error("Xatolik yuz berdi.");
            handleClose();
        })
    }


    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                O'zgartirish
            </Button>
            <Modal show={show} centered onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header className='bg-primary text-white' closeButton>
                        <Modal.Title className='fs-6'>Foydalanuvchini o'zgartish</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group className="mb-3" controlId="formLogin">
                            <Form.Label>Foydalanuvchi nomi(login)</Form.Label>
                            <Form.Control onChange={handleChange} value={updateUser.name} required name='name' type="text" placeholder="Username" />
                            <Form.Text className="text-muted">
                                Loginni o'zgartirishingiz kerak bo'lsa o'zgartiring. aks holda tegmang
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formFullName">
                            <Form.Label>FISH</Form.Label>
                            <Form.Control name='full_name' onChange={handleChange} value={updateUser.full_name} type="text" placeholder="Enter email" />
                            <Form.Text className="text-muted">
                                FISHni o'zgartirishingiz kerak bo'lsa o'zgartiring. aks holda tegmang
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control onChange={handleChange} value={updateUser.password} name='password' type="password" placeholder="Parol" />
                            <Form.Text className="text-muted">
                                Parolni o'zgartirishingiz kerak bo'lsa o'zgartiring aks holda tegmang
                            </Form.Text>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formGroups">
                            <Form.Label>Guruhni tanlang</Form.Label>
                            <Select
                                name='groups'
                                onChange={(newValue) => {
                                    setUpdateUser({
                                        ...updateUser,
                                        groups: newValue
                                    });
                                }} placeholder="Guruhlarni tanlang" className='mb-3' isMulti options={groups} />
                            <Form.Text className="text-muted">
                                Qo'shilishi kerak bo'lgan guruhlarni tanlang
                            </Form.Text>
                        </Form.Group>

                    </Modal.Body>
                    <Modal.Footer>

                        <Button variant="danger" onClick={handleClose}>
                            Yopish
                        </Button>
                        <Button variant="primary" type="submit">
                            Yuborish
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
}

export default ChangeUser;
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  students: {
    student_id_number: "",
    full_name: "",
    gender: "",
    JSHSHIR: "",
    passport: ""
  },
  educational_information: {
    department_id: "",
    specality: "",
    group_id: "",
    paymentForm: "",
    level: "",
  },
  accommodations: {
    country: "",
    province: "",
    disctrict: "",
  },
  additional_information: {
    iron_notebook: false,
    youth_notebook: false,
    orphan: false,
    amputatuion: false,
    ligota: false,
    iab_child: false,
    military_child: false,
    desert: false,
    woman: false,
    purpose: false,
  },
  study_informations: {
    enter_order: "",
    enter_date: {
      begin: "",
      end: ""
    },
    leave_order: "",
    leave_date: {
      begin: "",
      end: ""
    },
    academic_holiday: "",
    academic_holiday_date: {
      begin: "",
      end: ""
    },
    sorry_command: "",
    sorry_command_date: {
      begin: "",
      end: ""
    },
    stay_command: "",
    stay_command_date: {
      begin: "",
      end: ""
    },
    first_to_second: "",
    first_to_second_date: {
      begin: "",
      end: ""
    },
    second_to_third: "",
    second_to_third_date: {
      begin: "",
      end: ""
    },
    finish_command_date: {
      begin: "",
      end: ""
    },
  }
};

const findSlice = createSlice({
  name: "findSlice",
  initialState,
  reducers: {
    findChange(state, actions) {
      if (actions.payload.name === "department_id") {
        state[actions.payload.type]["specality"] = "";
        state[actions.payload.type]["group_id"] = "";
      }
      if (actions?.payload?.date) {
        state[actions.payload.type][actions.payload.name][actions.payload.date] = actions.payload.value;
      } else
        state[actions.payload.type][actions.payload.name] = actions.payload.value;
    },
    clearFilter() {
      return initialState;
    },
  },
});

export const { findChange, clearFilter } = findSlice.actions;
export default findSlice.reducer;

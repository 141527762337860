import React from 'react'

function Loader({ loading }) {
    return (
        <div className={`loader-content ${loading ? "d-flex" : "d-none"}`}>
            <div className="d-flex flex-column">
                <span className='loader'> </span>
                <span className='p-1 text-white bg-dark'>Ma'lumotlar serverdan yuklanmoqda...</span>
            </div>
        </div>
    )
}

export default Loader
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import makePdfData from '../components/CreatePdf/data'
import Loader from '../components/Loader'
import DeleteConform from '../components/modals/Delete'
import { toast } from 'react-toastify'
import { useCookies } from 'react-cookie'
import { URL_IMG } from '../routes/constants'
import { enter_comments } from '../store/Slices/studentSlice/enter_commit'

function Student() {
    const { id } = useParams()
    const [student, setStudent] = useState({});
    const [loading, setLoader] = useState({});
    const [show, setShow] = useState(false);
    const navigate = useNavigate()
    const [cookie] = useCookies()
    const perms = JSON.parse(localStorage.getItem("_perms"))
    // Lanch delete modal function
    const handleShow = () => setShow(true);


    useEffect(() => {
        const scrollToBottom = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        };

        scrollToBottom()
        setLoader(true)
        axios.get(`students/${id}`)?.then(res => {
            if (!res.data.data.success) {
                toast.error(res.data.data.message)
                navigate(-1)
            }
            else
                setStudent(res?.data.data.student)
            setLoader(false)
        }).catch(err => {
            console.log(err);

        })

    }, [id])



    return (
        <div className='container'>
            <Loader loading={loading} />

            <DeleteConform show={show} setShow={setShow} />
            <Row>
                <Col>
                    <div className="section d-flex justify-content-between mb-3">
                        <h3>Talaba ma'lumoti</h3>
                        <div>
                            {perms.includes("update_student") && <Link to={`/add/${id}`} className='me-2 btn btn-primary' ><i className="bi text-white bi-pen"></i></Link>}
                            <Button variant='success' className='me-2' onClick={() => makePdfData(student)}><i className="bi bi-box-arrow-in-down"></i></Button>
                            {
                                JSON.parse(localStorage.getItem("_perms")).includes("delete_student") &&
                                <Button variant='danger' onClick={() => handleShow()}><i className="bi bi-trash3"></i></Button>
                            }
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <div className="section">
                        <h3 className='border-bottom'>Passport ma'lumotlari</h3>
                        <Row>
                            <Col xs={12} md={6}>
                                <Row>
                                    <Col className='mb-2 text-center' xs={12} md={5} lg={3} >
                                        {!student?.image ?
                                            <span className='user_img d-inline-block'></span> :
                                            <img className='border user_img' src={`${URL_IMG}${student.image.ImagePath}`} alt="img" />} </Col>
                                    <Col>
                                        <p><b>F.I.Sh:</b> {student?.full_name}</p>
                                        <p><b>Jinsi:</b> {student?.gender === "0" ? "Ayol" : "Erkak"}</p>
                                        <p><b>Millati:</b> {student?.nationality}</p>
                                        <p><b>Fuqoroligi:</b> {student?.citizenship}</p>
                                        <p><b>Telefon raqmi:</b> {student?.phone}</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} md={6}>
                                <p><b>Tug'ilgan sanasi:</b> {student?.birthday}</p>
                                <p><b>Passport raqami:</b> {student?.passport}</p>
                                <p><b>Passport qaysi IIB  tomonidan qachon berilgan:</b> {student?.get_passport}</p>
                                <p><b>JSHSHIR:</b> {student?.JSHSHIR}</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm={12} lg={6}>
                    <div className="section mb-3">
                        <h3 className='border-bottom mb-3'>O'quv ma'lumotlari</h3>
                        <p><b>Fakulteti:</b> {student?.educational_information?.department?.name}</p>
                        <p><b>Yo'nalish kodi:</b> {student?.educational_information?.group?.specialty.specialty_code}</p>
                        <p><b>Yo'nalishi:</b> {student?.educational_information?.group?.specialty.specialty}</p>
                        <p><b>Guruhi:</b> {student?.educational_information?.group.group}</p>
                        <p><b>Kursi:</b> {student?.educational_information?.level}-kurs</p>
                        <p><b>Ta'lim shakli:</b> {student?.educational_information?.educationForm}</p>
                        <p><b>To'lov shakli:</b> {student?.educational_information?.paymentForm}</p>
                        <p><b>Ta'lim turi:</b> {student?.educational_information?.educationType}</p>
                        <p className='m-0'><b>Kirish bali:</b> {student?.educational_information?.mark}</p>
                    </div>
                    <div className="section mb-3">
                        <h3 className='border-bottom mb-3'>Talaba yashash joy ma'lumotlari</h3>
                        <p><b>Davlat:</b> {student?.accomodation?.country}</p>
                        <p><b>Viloyat:</b> {student?.accomodation?.province}</p>
                        <p><b>Tuman(shahar):</b> {student?.accomodation?.disctrict}</p>
                        <p><b>Doimiy yashash Manzili:</b> {student?.accomodation?.residintial_adress}</p>
                        <p className='m-0'><b>Vaqtinchalik yashash joyi:</b> {student?.accomodation?.accommodation}</p>
                    </div>
                </Col>
                <Col sm={12} lg={6}>
                    <div className="section mb-3">
                        <h3 className='border-bottom mb-3'>Bitiruv ma'lumotlari</h3>
                        <p><b>Tamomlagan talim muassasasi (kollej, litsey, maktab, universitet) nomi:</b> {student?.graduate?.finished}</p>
                    </div>
                    <div className="section mb-3">
                        <h3 className='border-bottom mb-3'>Talaba yutuqlari</h3>
                        <p><b>Chet tilidan til sertifikati:</b> {student?.achievement?.lang_certificate?.length !== 0 ? student?.achievement?.lang_certificate : "Mavjud emas"}</p>
                        <p><b>Jahon va respublika  miqyosidagi musobaqalar sovrindori:</b> {student?.achievement?.champion?.length !== 0 ? student?.achievement?.champion : "Mavjud emas"}</p>
                        <p><b>Davlat mukofotlari yoki stipendiyasi</b> {student?.achievement?.scholarship?.length !== 0 ? student?.achievement?.scholarship : "Mavjud emas"}</p>
                    </div>
                    <div className="section mb-3">
                        <h3 className='border-bottom mb-3'>Qo'shimcha ma'lumotlar</h3>
                        {
                            student?.additional_information ?
                                <>
                                    {student?.additional_information?.woman_notebook !== null &&
                                        <p className='m-0'><b>Ayollar daftariga kirilgan</b></p>}
                                    {student?.additional_information?.amputatuion !== null &&
                                        <p className='m-0'><b>Nogironligi mavjud bo‘lgan shaxslar uchun ajratilgan qo‘shimcha kvota</b></p>}
                                    {student?.additional_information?.youth_notebook !== null &&
                                        <p className='m-0'><b>Yoshlar daftarga kiritilgan</b></p>}
                                    {student?.additional_information?.orphan !== null &&
                                        <p className='m-0'><b>Davlat qaramog'ida (chin yetim)</b></p>}
                                    {student?.additional_information?.iron_notebook !== null &&
                                        <p className='m-0'><b>Temir daftarga kiritilgan</b></p>}
                                    {student?.additional_information?.ligota !== null &&
                                        <p className='m-0'><b>Muddatli harbiy xizmatni o‘tab harbiy qism qo‘mondonligi tavsiyanomasiga ega</b></p>}
                                    {student?.additional_information?.iab_child !== null &&
                                        <p className='m-0'><b>Ichki ishlar organlari xodimlari farzandlari uchun ajratilgan qo‘shimcha kvota</b></p>}
                                    {student?.additional_information?.military_child !== null &&
                                        <p className='m-0'><b>Qurolli Kuchlari xodimlari farzandlari uchun ajratilgan qo‘shimcha kvota</b></p>}
                                    {student?.additional_information?.desert !== null &&
                                        <p className='m-0'><b>Olis cho‘l tumanlari uchun ajratilgan qo‘shimcha kvota</b></p>}
                                    {student?.additional_information?.woman !== null &&
                                        <p className='m-0'><b>Xotin-qizlarni qo‘llab-quvvatlash maqsadida berilgan tavsiyanoma asosida qabul</b></p>}
                                    {student?.additional_information?.purpose !== null &&
                                        <p className='m-0'><b>Maqsadli qabul</b></p>}
                                </> :
                                "Ma'lumot qo'shilmagan yoki xatolik"
                        }

                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="section mb-3">
                        <h3 className='border-bottom mb-3'>Talaba buruqlari</h3>
                        <Row>
                            <Col xs={12} md={7}>
                                <p><b>Talabaning o'qishga kirgan, o'qishini ko'chirgan va tiklagan  buyrug'i:</b> {student?.study_information?.enter_order !== null ? student?.study_information?.enter_order : "Mavjud emas"}</p>
                            </Col>
                            <Col>
                                <p><b>Sanasi:</b> {student?.study_information?.enter_date !== null ? student?.study_information?.enter_date : "Mavjud emas"}</p>
                            </Col>
                            <Col>
                                <p><b>Izoh:</b> {student?.study_information?.enter_comment !== null ? enter_comments.find(item => item.value === student?.study_information?.enter_comment)?.name : "Izohsiz"}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={7}>
                                <p><b>Talabalar safidan chiqarilgan buyrug'i:</b> {student?.study_information?.leave_order !== null ? student?.study_information?.enter_order : "Mavjud emas"}</p>
                            </Col>
                            <Col>
                                <p><b>Sanasi:</b> {student?.study_information?.leave_date !== null ? student?.study_information?.leave_date : "Mavjud emas"}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={7}>
                                <p><b>Talabaga akademik ta'til berilgan buyrug'i:</b> {student?.study_information?.academic_holiday !== null ? student?.study_information?.academic_holiday : "Mavjud emas"}</p>
                            </Col>
                            <Col>
                                <p><b>Sanasi:</b> {student?.study_information?.academic_holiday_date !== null ? student?.study_information?.academic_holiday_date : "Mavjud emas"}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={7}>
                                <p><b>Talaba 1-kursdan 2-kursga o'tish buyrug'i:</b> {student?.study_information?.first_to_second !== null ? student?.study_information?.first_to_second : "Mavjud emas"}</p>
                            </Col>
                            <Col>
                                <p><b>Sanasi:</b> {student?.study_information?.first_to_second_date !== null ? student?.study_information?.first_to_second_date : "Mavjud emas"}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={7}>
                                <p><b>Talaba 2-kursdan 3-kursga o'tish buyrug'i:</b> {student?.study_information?.second_to_third !== null ? student?.study_information?.second_to_third : "Mavjud emas"}</p>
                            </Col>
                            <Col>
                                <p><b>Sanasi:</b> {student?.study_information?.second_to_third_date !== null ? student?.study_information?.second_to_third_date : "Mavjud emas"}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={7}>
                                <p><b>Talaba 3-kursdan 4-kursga o'tish buyrug'i:</b> {student?.study_information?.third_to_fourth !== null ? student?.study_information?.third_to_fourth : "Mavjud emas"}</p>
                            </Col>
                            <Col>
                                <p><b>Sanasi:</b> {student?.study_information?.third_to_fourth_date !== null ? student?.study_information?.third_to_fourth_date : "Mavjud emas"}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={7}>
                                <p><b>Talabaga hayfsan berilgan buyrug'i:</b> {student?.study_information?.sorry_command !== null ? student?.study_information?.sorry_command : "Mavjud emas"}</p>
                            </Col>
                            <Col>
                                <p><b>Sanasi:</b> {student?.study_information?.sorry_command_date !== null ? student?.study_information?.sorry_command_date : "Mavjud emas"}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={7}>
                                <p><b>Universitetni tamomlash haqida buyrug'i:</b> {student?.study_information?.finish_command !== null ? student?.study_information?.finish_command : "Mavjud emas"}</p>
                            </Col>
                            <Col>
                                <p><b>Sanasi:</b> {student?.study_information?.finish_command_date !== null ? student?.study_information?.finish_command_date : "Mavjud emas"}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={7}>
                                <p><b>Tamomlash INDEX:</b> {student?.study_information?.finish_index !== null ? student?.study_information?.finish_index : "Mavjud emas"}</p>
                            </Col>
                            <Col>
                                <p><b>Izoh:</b> {student?.study_information?.finish_comment !== null ? student?.study_information?.finish_comment : "Mavjud emas"}</p>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Student
import React from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { addWork, editWork, removeWork } from '../../store/Slices/studentSlice/studentSlice';

export default function WorksForm() {
    const dispatch = useDispatch();
    const work = useSelector(state => state.student.body.work)
    return (
        <div>
            <Card className='mt-4 mb-3'>
                <Card.Header className='bg-primary text-white'>Mehnat faoliyati</Card.Header>
                <Card.Body>
                    {
                        work.map((item, index) => (
                            <Row key={index} className='align-items-center'>
                                <Col xs={3} className='my-3'>
                                    <Form.Label>Mehnat faoliyati boshlanish yili</Form.Label>
                                    <Form.Control
                                        onChange={(e) => dispatch(editWork({ name: "begin", index: e.target.dataset.index, value: e.target.value }))}
                                        required
                                        data-index={index}
                                        type={"text"}
                                        value={item.begin}
                                    />
                                </Col>
                                <Col xs={3} className='my-3'>
                                    <Form.Label>Mehnat faoliyati tugagan yili</Form.Label>
                                    <Form.Control
                                        onChange={(e) => dispatch(editWork({ name: "end", index: e.target.dataset.index, value: e.target.value }))}
                                        required
                                        data-index={index}
                                        type={"text"}
                                        value={item.end}
                                    />
                                </Col>
                                <Col xs={5} className='my-3'>
                                    <Form.Label>Ish joyi va lavozimi</Form.Label>
                                    <Form.Control
                                        onChange={(e) => dispatch(editWork({ name: "work", index: e.target.dataset.index, value: e.target.value }))}
                                        required
                                        data-index={index}
                                        type={"text"}
                                        value={item.work}
                                    />
                                </Col>
                                <Col xs={1}>
                                    <Button className='my-3' onClick={() => dispatch(removeWork(index))} variant='danger'>O'chirish</Button>
                                </Col>
                            </Row>

                        ))
                    }
                    <Button onClick={() => dispatch(addWork())}>Mehnat faoliyati qo'shish</Button>
                </Card.Body>
            </Card>
        </div>
    )
}

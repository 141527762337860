import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Col, Pagination, Row } from 'react-bootstrap'
import { Link, useSearchParams } from 'react-router-dom'
import Loader from '../../components/Loader';
import DetachConform from '../../components/modals/Detouch';
import ChangeUser from '../../components/modals/ChangeUser';
import useCookies from 'react-cookie/es6/useCookies';

function Users() {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false)
    const perms = JSON.parse(localStorage.getItem("_perms"))
    const [groups, setGroups] = useState([]);
    let [searchParams, setSearchParams] = useSearchParams();
    const [cookie] = useCookies();
    useEffect(() => {
        if (cookie['_role'] === 'dean')
            axios.get('groups').then(res => {
                setGroups(res.data.data.groups.map(item => ({ label: item.group, value: item.id })))
            })
    }, [users])


    useEffect(() => {
        const param = searchParams.toString()
        console.log(param);
        setLoading(true)
        if (perms.length === 3) {
            axios.get(`/tyutors`).then(res => {
                setUsers(res.data.data.tyutors)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
                console.log(err)
            })
        }
        else
            axios.get(`/spadmin/users?${param}`).then(res => {
                setUsers(res.data.data.users)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
                console.log(err)
            })
    }, [searchParams])

    function setPagination(data) {
        let page = data.slice(data.search("page=") + 5);
        searchParams.set("page", page)
        setSearchParams(searchParams)
    }

    return (
        <div>
            <Loader loading={loading} />
            <div className='section'>
                <div className="d-flex justify-content-between mb-3">
                    <h2>Foydalanuvchilar</h2>
                    <Link to="adduser" className='btn d-flex align-items-center btn-primary text-white'>
                        <i className="bi bi-person-plus me-2"></i>
                        Foydalanuvchi qo'shish
                    </Link>
                </div>

                {
                    perms.length === 3 ? (
                        <>
                            <Row className='border-bottom py-1'>
                                <Col xs={1}>#</Col>
                                <Col xs={2}><b>Foydalanuvchi logini</b></Col>
                                <Col xs={2}><b>Foydalanuvchi to'liq ismi</b></Col>
                                <Col xs={5}><b>Guruhlari</b></Col>
                                <Col xs={1}><b>Harakatlar</b></Col>
                            </Row>
                            {
                                users?.map((user, index) => (
                                    <Row key={index} className='border-bottom py-1'>
                                        <Col xs={1}>{index + 1}</Col>
                                        <Col xs={2}><b>{user.name}</b></Col>
                                        <Col xs={2}>{user.full_name}</Col>
                                        <Col xs={5}>
                                            {
                                                user?.groups.map(group => (
                                                    <DetachConform key={group.id} setUsers={setUsers} groupId={group.id} name={group.group} setLoading={setLoading} id={user.id} />
                                                    // <span style={{ fontSize: "11px" }} className='btn text-white btn-success m-1'>{group.group}</span>
                                                ))
                                            }
                                        </Col>
                                        <Col xs={1}>
                                            <div><ChangeUser setGroups={setGroups} setLoading={setLoading} setUsers={setUsers} groups={groups} user={user} id={user.id} /></div>
                                        </Col>
                                    </Row>))
                            }

                        </>
                    ) :
                        users?.data?.map((user, index) => (
                            <Row key={index} className='border-bottom py-1'>
                                <Col xs={1}>{index + 1}</Col>
                                <Col xs={2}><b>{user.name}</b></Col>
                                <Col xs={3}>{user.full_name}</Col>
                                <Col xs={3}>{user?.departments[0]?.name}</Col>
                                <Col xs={3}>{user.created_at.split("T")[0]}</Col>
                            </Row>
                        ))
                }
            </div >
            <Pagination className='justify-content-center'>
                {
                    users?.links?.length >= 4 &&
                    users?.links?.map((link, index) => (
                        <Pagination.Item disabled={link.url === null} onClick={() => setPagination(link.url)} key={index} active={link.active}>
                            {link.label.replace('&laquo; Previous', 'Oldingi').replace('Next &raquo;', 'Keyingi')}
                        </Pagination.Item>
                    ))
                }</Pagination>
        </div>)
}

export default Users
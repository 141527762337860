import { Navigate } from "react-router-dom";
import Login from "../pages/Login";
import NotMatch from "../pages/NotMatch";
import ProtectedRoute from "./protected-route";
import {
  deanRouter,
  headerRoutes,
  spAdminRouter,
  tutorRoutes,
} from "./router-config";

/* --------------------------------------------------------- */
/* get cookie from cookie list */
function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/* --------------------------------------------------------- */
// Attach routes based on user role
let mainRoutes = [];

if (getCookie("_role") === "tutor") {
  mainRoutes = tutorRoutes;
}
if (getCookie("_role") === "super_admin") {
  mainRoutes = spAdminRouter;
}
if (getCookie("_role") === "dean") {
  mainRoutes = deanRouter;
}
if (getCookie("_role") === "student_staff") {
  mainRoutes = headerRoutes;
}

/* --------------------------------------------------------- */
// Create router config for main router
const routesConfig = [
  {
    element: <ProtectedRoute />,
    children: mainRoutes,
  },
];

/* --------------------------------------------------------- */
// Add defalult route for router
routesConfig.push({
  path: "/",
  element: <Navigate to="dashboard" />,
});

routesConfig.push({
  path: "/login",
  element: <Login />,
});

routesConfig.push({
  path: "*",
  element: <NotMatch />,
});

export default routesConfig;

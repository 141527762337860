import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import "./main.css"
function DetachConform({ id, setUsers, setLoading, name, groupId }) {
    // get student id for delete user
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    // Request for delete student
    const handleDelete = () => {

        axios.post(`/tyutors/${id}/detach/${groupId}`).then(res => {
            toast.success("Mufaqqiyatli o'chirildi.");
            handleClose()
            setShow(false)
            axios.get(`/tyutors`).then(res => {
                setUsers(res.data.data.tyutors)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
                console.log(err)
            })
            // navigate("/students")
        }).catch(err => {
            toast.error("Xatolik yuz berdi.");
            setShow(false)
        })
    }
    return (
        <>
            <Button className='delete_group p-1 m-1' variant="success" onClick={handleShow}>{name}</Button>
            <Modal show={show} centered onHide={handleClose}>
                <Modal.Header className='bg-danger text-white' closeButton>
                    <Modal.Title className='fs-6'>Guruhni o'chirishni tasdiqlang</Modal.Title>
                </Modal.Header>
                <Modal.Body>Siz haqiqatdan ham guruhni tyutordan o'chirmoqchimisiz?</Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Yopish
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        O'chirish
                    </Button>
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default DetachConform;
import React from 'react'
import { Form } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { handleBlur, handleChange } from '../../store/Slices/studentSlice/studentSlice'

export default function InputFeild({ section, item, index }) {
    const dispatch = useDispatch()

    return (
        <div>
            {console.log(item.last ? "^$|" + "([0-9]{2}|[0-9]{3})-BM" + `\\(${item.last}\\)` : item.value)}
            <Form.Control
                data-type={section}
                onBlur={e => dispatch(handleBlur({ type: e.target.dataset.type, index: e.target.dataset.index, value: e.target.value }))}
                onChange={(e) => dispatch(handleChange({ type: e.target.dataset.type, index: e.target.dataset.index, value: e.target.value }))}
                required={item.required ? true : false}
                pattern={!!item.last && "^$|" + "([0-9]{2}|[0-9]{3})-BM" + `\\(${item.last}\\)`}
                disabled={item.disabled ? true : false}
                data-index={index}
                type={item.type ? item.type : "text"}
                placeholder={item.placeholder}
                value={item.value}
            />
            <Form.Control.Feedback type="invalid">Buyruqni to'g'ri kiriting Misol: 45-BM({item.last})</Form.Control.Feedback>
            {item.alert && <Form.Text className='text-danger'><sup>*</sup>{item.alert} </Form.Text>}
        </div>
    )
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  passport: "is-active",
  "education-data": "",
  additional: "",
  personal: "",
  study_information: ""
};

const sendSlice = createSlice({
  name: "sendSlice",
  initialState,
  reducers: {
    changeActive(state, actions) {
      if (actions.payload.old) state[actions.payload.old] = "is-complete";
      state[actions.payload.current] = "is-active";
    },
    resetActive() {
      return initialState;
    },
  },
});

export const { changeActive, resetActive } = sendSlice.actions;
export default sendSlice.reducer;

import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import Loader from '../components/Loader';
import { useCookies } from 'react-cookie';
import { Link, useParams } from 'react-router-dom';
const colors = {
    none: "#e2725b",
    some: "#eb9c5c",
    up50: "#c5e384",
    full: "#57886c",
}

function GroupCompliteList() {
    const [cookie] = useCookies();
    const [count, setCount] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams()

    useEffect(() => {
        setLoading(true);
        let path_url = ""

        if (cookie["_role"] === "tutor") path_url = "statistics/get-update-student-count-by-tutor";
        else if (cookie["_role"] === "dean") path_url = "statistics/get-update-student-count-by-department";
        else path_url = "statistics/get-update-student-count-by-department/" + id;


        axios.get(path_url)
            .then(res => {
                setCount(res.data.data.data);
                setLoading(false)
            })
            .catch(err => setLoading(false))
    }, [cookie, id])

    return (
        <>
            <Loader loading={loading} />
            <div className='section'>
                <Row className='fw-bold border-bottom py-1'>
                    <Col xs={3}>Guruh nomi</Col>
                    <Col xs={2}>Jami talabari</Col>
                    <Col xs={3}>Ma'lumotlari kitilgan</Col>
                    <Col xs={3}>Qolgan talabalar</Col>
                </Row>
                {
                    count?.sort((a, b) => b.update_count - a.update_count).map(item => {
                        let percentage = (item.total - item.update_count) / item.total * 100;
                        let whatBackground = ""
                        if (percentage === 0) {
                            whatBackground = colors.full;
                        } else if (percentage < 50) {
                            whatBackground = colors.up50
                        } else if (percentage < 99) {
                            whatBackground = colors.some
                        } else if (percentage === 100) {
                            whatBackground = colors.none
                        }
                        return (
                            <Row key={item.group_id} className='border-bottom py-1'>
                                <Col style={{ color: whatBackground }} xs={3}><Link to={`/students?educational_information[group_id]=${item.group_id}`}>{item.group}</Link></Col>
                                <Col xs={2}>{item.total}</Col>
                                <Col xs={3}>{item.update_count}</Col>
                                <Col xs={3}>{item.total - item.update_count}</Col>
                            </Row>
                        )
                    })
                }
            </div>
        </>
    )
}

export default GroupCompliteList
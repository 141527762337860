import Add from "../pages/Add";
import Show from "../pages/Show";
import Student from "../pages/Student";
import NewUser from "../pages/NewUser";
import Users from "../pages/SpAdmin/Users";
import Home from "../pages/Home";
import { Navigate, Outlet } from "react-router-dom";
import GroupCompliteList from "../pages/GroupCompliteList";
import DepartmentCompliteList from "../pages/DepertmentCompliteList";

export const editStudents = [
  {
    index: true,
    element: <Navigate to={"/students"} />,
  },
  {
    path: ":id",
    element: (
      <Add
        title={"Talaba ma'lumotlari"}
        expected={[
          "passport",
          "education",
          "place_information",
          "additional_infromation",
          "educationData",
          "finished",
          "lang",
          "achievements",
          "reference",
          "relation",
          "work",
        ]}
      />
    ),
  },
];

export const showStudentRoute = [
  {
    index: true,
    element: <Show />,
  },
  {
    path: ":id",
    element: <Student />,
  },
];

export const userCreateRoute = [
  {
    index: true,
    element: <Users />,
  },
  {
    path: "adduser",
    element: <NewUser />,
  },
];

export const spAdminRouter = [
  {
    path: "users",
    element: <Outlet />,
    children: userCreateRoute,
  },
  {
    path: "dashboard",
    element: <Home />,
  },
  {
    path: "students",
    element: <Outlet />,
    children: showStudentRoute,
  },
  {
    path: "statistics",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <DepartmentCompliteList />,
      },
      {
        path: ":id",
        element: <GroupCompliteList />,
      }

    ]
  },
];

export const deanRouter = [
  {
    path: "users",
    element: <Outlet />,
    children: userCreateRoute,
  },
  {
    path: "dashboard",
    element: <Home />,
  },
  {
    path: "students",
    element: <Outlet />,
    children: showStudentRoute,
  },
  {
    path: "statistics",
    element: <Outlet />,
    children: [
      {
        path: ":id",
        element: <GroupCompliteList />,
      }
    ]
  },
];

export const tutorRoutes = [
  {
    path: "dashboard",
    element: <Home />,
  },
  {
    path: "students",
    element: <Outlet />,
    children: showStudentRoute,
  },
  {
    path: "add",
    element: <Outlet />,
    children: editStudents,
  },
  {
    path: "statistics",
    element: <Outlet />,
    children: [
      {
        path: ":id",
        element: <GroupCompliteList />,
      }
    ]
  },
];

export const headerRoutes = [
  {
    path: "dashboard",
    element: <Home />,
  },
  {
    path: "add",
    element: <Outlet />,
    children: editStudents,
  },
  {
    path: "students",
    element: <Outlet />,
    children: showStudentRoute,
  },
  {
    path: "statistics",
    element: <Outlet />,
    children: [
      {
        index: true,
        element: <DepartmentCompliteList />,
      },
      {
        path: ":id",
        element: <GroupCompliteList />,
      }

    ]
  },
];

import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAuth } from '../routes/Auth'
import { Button } from 'react-bootstrap'
import useCookies from 'react-cookie/es6/useCookies'

const MenuBar = () => {
    const menu = useSelector((state) => state.menu)
    const auth = useAuth();
    const perms = auth.perms;
    const navigate = useNavigate();

    const [cookie] = useCookies();

    /* --------------------------------------------------------- */
    // Run this function when the user logs out
    function logOut() {
        auth.logOut()
        navigate('/login');
    }

    return (
        <nav className={`menu ${!menu.isOpen && "close"}`}>
            <div className='logo d-flex align-items-center'>
                <div><img src={`https://www.samdu.uz/images/logo-128.png`} alt="logo" /></div>
                <p className='m-0 ms-2'><b>Samarqand davlat universiteti <br /> talabalar ma'lumotlari sayti</b></p>
            </div>
            <div>
                <ul className='menu-items'>
                    <li><NavLink to="/dashboard"><i className="bi bi-house-fill me-2"></i>Bosh sahifa</NavLink></li>
                    {
                        //if permission is available it will run if permission is available
                        (perms.includes("read_student_of_group") || perms.includes("read_student") || perms.includes("read_student_of_department")) &&
                        <li><NavLink to="/students"><i className="bi bi-card-checklist me-2"></i>Studentlar ro'yxati</NavLink></li>
                    }

                    {
                        // Will run if permission is available
                        perms.includes("create_tutor") &&
                        <li><NavLink to="/users"><i className="bi bi-person-fill me-2"></i>Foydalanuchilar</NavLink></li>
                    }
                    {
                        // Will run if permission is available

                        (cookie["_role"] === "tutor") || (cookie["_role"] === "dean") ?
                            <li><NavLink to={`/statistics/${cookie["department_id"]}`}><i className="bi bi-bar-chart-line me-2"></i>Statistika</NavLink></li> :
                            <li><NavLink to="/statistics"><i className="bi bi-bar-chart-line me-2"></i>Statistika</NavLink></li>
                    }
                </ul>
            </div>

            <div className='d-flex justify-content-begin mx-3 mt-5'>
                <Button className='d-flex align-items-center' onClick={logOut}>

                    <i className="bi bi-box-arrow-left"></i>
                    <p className='m-0 ms-2'>Profildan chiqish</p>
                </Button>
            </div>
        </nav>
    )
}
export default MenuBar